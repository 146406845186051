// Import the library function and icon packs
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBuilding } from '@fortawesome/sharp-solid-svg-icons';
import { faGrid2, 
    faGrid2Plus, 
    faUsersGear,
    faBackwardStep,
    faEnvelope,
    faCircleUser,
    faCog,
    faLeftLong,
    faPlus,
    faInfoCircle,
    faRightFromBracket,
    faUserCog,
    faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons';
// Add more icons as needed

// Add icons to the library
library.add(faBuilding, 
    faGrid2,
    faGrid2Plus, 
    faUsersGear,
    faBackwardStep,
    faEnvelope,
    faCircleUser,
    faCog,
    faLeftLong,
    faPlus,
    faInfoCircle,
    faRightFromBracket,
    faUserCog,
    faScrewdriverWrench );