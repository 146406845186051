import React, {useContext} from "react";
import { LNotification, LNotificationGroup, LFade } from "@loffa/gpp-modules";
import NotificationContext from "../../../context/NotificationContext";

export default function FooterNotification() {
  const notificationContext = useContext(NotificationContext);
  let notifications = notificationContext.getNotifications();

  return (
    <LNotificationGroup>
      <LFade>
        {notifications.length > 0 &&
          notifications.map((notification) => (
            <LNotification
              key={notification.key}
              type={{ style: notification.type, icon: true }}
              closable={true}
              onClose={() => notificationContext.removeNotification(notification.key)}
            >
              <span>{notification.message}</span>
            </LNotification>
          ))}
      </LFade>
    </LNotificationGroup>
  );
}