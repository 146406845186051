import React from "react";
import { ClientTable } from "./CTable/CTable";

function ClientManagement() {
  return (
    <div>
      <ClientTable></ClientTable>
    </div>
  );
}

export { ClientManagement };
