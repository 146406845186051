import React, { useState, useContext, useEffect, memo, useCallback } from 'react';
import { AuthContext } from "../.././../../../../context/AuthContext";
import NotificationContext from "../../../../../../context/NotificationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LButton
} from "@loffa/gpp-modules";
import styles from "./UserTableOktaStatusCell.module.css";

function UserTableOktaStatusCell(props) {
  const [data, setData] = useState(null);
  const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
  const authContext = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);
  const [currProps, setCurrProps] = useState(props);
  const [key, setKey] = useState(0);
  const fetchData = useCallback(() => {
    if (data == null) {
      let url = GATEWAY_BASE + "/multitenancy/api/Users/" + props.userGuid + "/GetOktaStatus";

      try {
        fetch(url, {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
          },
        })
          .then(response => {
            if (response.ok) {
              return response.text();
            }
            else {
              throw new Error("Error getting Okta Status: " + response.status + " " + response.statusText);
            }
          })
          .then(res => {
            var titleCaseData = res;

            if (titleCaseData !== 'N/A') {
              titleCaseData = titleCaseData.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
            }

            setData(titleCaseData);
          });
      } catch (error) {
        notificationContext.addError(error.message);
      };
    }
  }, [GATEWAY_BASE, authContext, data, notificationContext, props.userGuid]);

  useEffect(() => {    
    setKey((prevKey) => prevKey + 1);
    
    if (data == null)
      fetchData();
  }, [data, props.oktaId, fetchData]);

  useEffect(() => {
    if (data != null && props.oktaId !== currProps.oktaId){
      setCurrProps(props);
      setData(null);
    }
  }, [props.oktaId, currProps.oktaId, data, props, setCurrProps, setData]);


  let onResendEmailClick = async (oktaId, sentEmail) => {
    let url = GATEWAY_BASE + "/identity/api/Users/ByOktaId/" + oktaId + "/reactivateUser";

    try {
      await fetch(url, {
        method: "POST",        
        mode: "cors",
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authContext.authState().accessToken.accessToken}`,
        }),
        body: JSON.stringify({
          sendEmail: true,
          email: sentEmail
        }),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Error trying to resend: " + response.status + " " + response.statusText);
          }
        })
        .then(json => {
          notificationContext.addSuccess("Resent Welcome Email to " + sentEmail);
        });
    } catch (error) {
      notificationContext.addError(error.message);
    };
  };

  const buildCellBody = () => {
    fetchData();

    return (
      <div key={key}>
        {data}
        {data !== "Provisioned" ?
          null
          :
          (
            <LButton
              className={styles.resendBtn}
              themeColor="primary"
              tooltip="Resend Welcome Email"
              tooltipPosition="topRight"
              onClick={() => onResendEmailClick(props.oktaId, props.userEmail)}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-envelope"
              />
            </LButton>)
        }</div>);
  }

  if (!data) {
    return <span>Loading...</span>;
  }

  return (
    <span>
      {buildCellBody()}
    </span>
  );
}

function propsAreEqual(prevProps, nextProps) {
  return prevProps.oktaId === nextProps.oktaId;
}

export default memo(UserTableOktaStatusCell, propsAreEqual);