import React, { useContext, useState, useEffect, useCallback } from "react";
import { LButton, LFormInput } from "@loffa/gpp-modules";
import { AuthContext } from "../../../../../../context/AuthContext";
const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function ClientProfile(props) {
    const context = useContext(AuthContext);
    const [state, setState] = useState({
        clientGuid: props.clientGuid,
        client: {},
        isModified: false,
    });

    const bindData = useCallback(() => {
        let url =
            GATEWAY_BASE +
            "/multitenancy/api/Clients/" +
            state.clientGuid;

        fetch(url, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
            },
        })
            .then((res) => {
                if (res.status === 204) {
                    return [];
                } else {
                    return res.json();
                }
            })
            .then(function (response) {
                
                setState(s => ({
                    ...s,
                    client: response,
                    initialClient: response
                }));

            })
            .catch((error) => {
                console.log(error);
            });
    }, [context, state.clientGuid]);

    useEffect(() => {
        bindData();
    }, [bindData]);

    const handleStateChange = (e) => {
        setState({
            ...state,
            client: {
                ...state.client,
                [e.target.name]: e.target.value,
                lastUpdatedBy: context.userInfo().loffaUserGuid,
            },
            isModified: true,
        });
    };

    const resetForm = () => {
        setState({
            ...state,
            client: state.initialClient
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let authContext = context;

        let method = "PUT";
        let url =
            GATEWAY_BASE +
            "/multitenancy/api/Clients/";
        
        fetch(url, {
            method: method,
            mode: "cors",
            headers: {
                Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(state.client),
        }).then(
            (res) => {
                if (res.status === 200) {                    
                    setState({
                        ...state,
                        isModified: false,
                    });
                    console.log(res);
                    props.refreshList();
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <LFormInput
                    labelName="Client Name"
                    type="input"
                    onChange={(event) => handleStateChange(event)}
                    input={{
                        value: state.client.name,
                        onChange: (event) => handleStateChange(event),
                        name: "name",
                    }}
                />
                <LFormInput
                    labelName="Client DTC Number"
                    type="input"
                    onChange={(event) => handleStateChange(event)}
                    input={{
                        value: state.client.dtcNumber,
                        onChange: (event) => handleStateChange(event),
                        name: "dtcNumber",
                    }}
                />
                <LFormInput
                    labelName="PBIN/FVD Integration"
                    type="switch"
                    onChange={(event) => handleStateChange(event)}
                    switch={{
                        value: state.client.pbinFVDIntegration,
                        onChange: (event) => handleStateChange(event),
                        name: "pbinFVDIntegration",
                        onLabel: "Enabled",
                        offLabel: "Disabled",
                        checked: state.client.pbinFVDIntegration,
                        width: 90
                    }}
                />
                {state.isModified && (
                    <div>
                        <LButton onClick={resetForm} type="button">
                            Discard Changes
                        </LButton>
                        <span> </span>
                        <LButton themeColor="primary" className="green" type="submit">
                            Save
                        </LButton>
                    </div>
                )}
            </form>
        </div>
    );
}

export { ClientProfile };
