import React, { useContext, useRef } from "react";
import styles from "./HeaderUserDetails.module.css";
import { AuthContext } from "../../../context/AuthContext";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOutsideClick } from "../../../helpers/OutsideClickHook";

export function HeaderUserDetails({ isVisible, hideUserDetails, onLogoutClick }) {
  const context = useContext(AuthContext);
  const wrapperRef = useRef(null);

  // Use the custom hook for detecting outside clicks
  useOutsideClick(wrapperRef, () => {
    if (isVisible) {
      hideUserDetails();
    }
  });

  return (
    <div id="profileUserDetail" ref={wrapperRef} className={styles.headerUserDetails}>
      <div className={styles.headerUserName}>
        {context.userInfo().name}
      </div>

      <div className={styles.headerUserRole}>
        {context.userInfo().role}
      </div>
      <hr />
      <div>
        <NavLink
          to="/dashboard/accountsettings"
        >
          <FontAwesomeIcon icon={["fas", "user-cog"]} /> Account Settings
        </NavLink>
      </div>
      <div>
        <a href="#top" onClick={() => onLogoutClick()}>
          <FontAwesomeIcon icon={["fas", "sign-out-alt"]} /> Log Out
        </a>
      </div>
    </div>
  );
}
