import styles from "./Header.module.css";
import React, { useState, useContext } from "react";
import LoffaHeaderLogo from "../../assets/Loffa_Logo_sm.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HeaderUserDetails } from "./HeaderUserDetails/HeaderUserDetails";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const VERSION_NUMBER = process.env.REACT_APP_VERSION_NUMBER;
const VERSION_DATE = process.env.REACT_APP_VERSION_DATE;
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export function Header(props) {
  const context = useContext(AuthContext);
  const [state, setState] = useState({
    showUserInformation: false,
  });

  const setShowUserInformation = (showUI) => {
    setState({ ...state, showUserInformation: showUI });
  };

  return (
    <div className={styles.header}>
      <div className={styles.menu}>
        <div className={styles.brand}>
          <div className={styles.headerLogoContainer}>
            <img src={LoffaHeaderLogo} alt="Loffa Interactive Logo" />
          </div>
          <ul className={styles.navigation}>
            <NavLink
              to="/dashboard/products"
              style={({ isActive }) => ({ color: isActive ? styles.selectedNavLink : '' })}
            >
              <li>My Products</li>
            </NavLink>
            {context.userInfo().role === "Super Admin" && (
              <NavLink
                to="/dashboard/administration"
                style={({ isActive }) => ({ color: isActive ? styles.selectedNavLink : '' })}
              >
                <li>Administration</li>
              </NavLink>
            )}
          </ul>
        </div>
        <div className={styles.userActionGroup}>
          <div className={styles.versionHeader}>
              {ENVIRONMENT} v.{VERSION_NUMBER} Released On: {VERSION_DATE}
          </div>
          <div
            className={
              (state.showUserInformation ? "active " : "") +
              styles.userInformation
            }
            onClick={() => setShowUserInformation(!state.showUserInformation)
            }
          >
            <FontAwesomeIcon icon={["fas", "user-circle"]} />
            <span className={styles.userName}>
              {context.userInfo().email}
            </span>
          </div>
          {state.showUserInformation && (
            <HeaderUserDetails
              isVisible={state.showUserInformation}
              onLogoutClick={props.onLogoutClick}
              userName={props.userName}
              hideUserDetails={() => setShowUserInformation(false)}
            ></HeaderUserDetails>
          )}
        </div>
      </div>
    </div>
  );
}
