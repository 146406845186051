import React, { useState, useContext } from "react";
import { LButton, LGrid, LFade } from "@loffa/gpp-modules";
import { CPUserManagementAssign } from "./CPUManagementAssign/CPUManagementAssign";
import { CPUserManagementForm } from "./CPUManagementForm/CPUManagementForm";
import { AuthContext } from "../../../../../../../context/AuthContext";
import NotificationContext from "../../../../../../../context/NotificationContext";
import KeyBasedLButton from '../../../../../../Shared/KeyBasedLButton';

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function CPUserManagement(props) {
  const context = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);

  const [state, setState] = useState({
    isAssignUser: false,
    isAddUpdateUser: false,
    selectedClient: props.selectedClient,
    selectedProduct: props.selectedProduct,
    selectedUser: null,
    isLoading: false
  });

  const unassignUser = (existingUser) => {    
    const removedUser = { ...existingUser };

    setState({ ...state, isLoading: true});

    let url =
      GATEWAY_BASE +
      "/multitenancy/api/Clients/" +
      state.selectedClient.clientGuid +
      "/Products/" +
      state.selectedProduct.productGuid +
      "/User/" +
      existingUser.userGuid;

    fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ isActiveInProduct: false }),
    })
      .then(function (response) {
        if (response.ok) {
          return unassignFromProduct(existingUser).then(() => {
            props.rebind(); 
            setState({ ...state, isLoading: false});
            notificationContext.addSuccess(removedUser.getFullName + " has been unassigned.");
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const unassignFromProduct = (existingUser) => {
    const url = props.productUrl + "/api/multitenancy/updateUser";

    existingUser.isActiveInProduct = false;

    return fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
        "Content-Type": "application/json",
        ClientGuid: state.selectedClient.clientGuid,
      },
      body: JSON.stringify(existingUser),
    }).then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const assignUser = () => {
    props.rebind();
  };

  const displayAssignUser = () => {
    setState({ ...state, isAssignUser: true });
  };

  const closeAssignUser = () => {
    setState({ ...state, isAssignUser: false, isAddUpdateUser: false });
  };

  const editUserPermission = (existingUser) => {
    setState({ ...state, isAddUpdateUser: true, selectedUser: existingUser });
  };

  const saveForm = () => {
    setState({ ...state, isAssignUser: false, isAddUpdateUser: false });
    props.rebind();
    notificationContext.addSuccess(state.selectedUser.getFullName + " has been successfully updated.");
  };

  const getCPUserManagementForm = () => {
    return (
      <CPUserManagementForm
        productUrl={props.productUrl}
        selectedUser={state.selectedUser}
        clientGuid={props.selectedClient.clientGuid}
        productGuid={props.selectedProduct.productGuid}
        configType={props.selectedProduct.permissionConfigurationTypeId}
        saveForm={saveForm}
        closeForm={closeAssignUser}
      ></CPUserManagementForm>
    );
  };


  let unassignCell = (value) => {
    let userGuid = value.dataItem.userGuid;
    let existingUser = props.data.find(
      (obj) => obj.userGuid === userGuid
    );

    return (
      <td>
        <KeyBasedLButton
          themeColor="primary"
          className="green"
          onClick={() => unassignUser(existingUser)}
          parentKey={existingUser.userGuid}
          buttonText={"Unassign"}
        >          
        </KeyBasedLButton>
      </td>
    );
  };

  let editPermissionsCell = (value) => {
    let userGuid = value.dataItem.userGuid;
    let existingUser = props.data.find(
      (obj) => obj.userGuid === userGuid
    );

    return (
      <td>
        <KeyBasedLButton
          themeColor="primary"
          className="green"
          onClick={() => editUserPermission(existingUser)}
          parentKey={existingUser.userGuid}
          buttonText={"Edit"}
        >          
        </KeyBasedLButton>
      </td>
    );
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  return (
    <div className="relative-div">
      {state.isLoading && loadingPanel}
      <LButton
        themeColor="primary"
        className="green"
        onClick={displayAssignUser}
      >
        Assign User
      </LButton>
      <LFade transitionEnterDuration={200}>
        {state.isAssignUser && (
          <CPUserManagementAssign
            productUrl={props.productUrl}
            assignUser={assignUser}
            selectedClient={state.selectedClient}
            selectedProduct={state.selectedProduct}
            selectedUser={state.selectedUser}
            closeForm={closeAssignUser}
            saveForm={saveForm}
            addEditUser={(user) => editUserPermission(user)}
          ></CPUserManagementAssign>
        )}
      </LFade>
      <LFade transitionEnterDuration={200}>
        {state.isAddUpdateUser && getCPUserManagementForm()}
      </LFade>
      {props.data !== null && props.data.length > 0 && (
        <LGrid
          sortable
          filterable
          pageable
          data={props.data}
          style={{ height: "400px" }}
          columns={[
            {
              field: "getFullName",
              title: "Name",
            },
            {
              field: "email",
              title: "Email",
              width: "250px",
            },
            {
              field: "userGuid",
              title: "Permissions",
              width: "250px",
              filterable: false,
              sortable: false,
              cell: editPermissionsCell,
            },
            {
              field: "userGuid",
              title: " ",
              width: "250px",
              filterable: false,
              sortable: false,
              cell: unassignCell,
            },
          ]}
        ></LGrid>
      )}
      {(props.data === null || props.data.length === 0) && <div>No users assigned</div>}
    </div>
  );
}

export { CPUserManagement };
