import React, { useContext, useEffect, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LButton,
  LGrid,
  LFade,
  LNotification,
  LNotificationGroup,
  LTabStrip,
} from "@loffa/gpp-modules";
import { AuthContext } from "../../../../../context/AuthContext";
import { ClientForm } from "./CForm/CForm";
import { ClientProducts } from "./CProducts/CProducts";
import { ClientProfile } from "./CProfile/CProfile";
import { ClientProductManagement } from "./CProductManagement/CProductManagement";
import styles from "./CTable.module.css";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function ClientTable(props) {
  const context = useContext(AuthContext);
  const [state, setState] = useState({
    authLoaded: false,
    headersLoaded: false,
    displayClientForm: false,
    displayClientFormSaveSuccess: false,
    selectedClient: null,
    selectedProduct: null,
    selectedClientProduct: null,
    manageClientProduct: false,
    isLoading: false
  });

  const [headers, setHeaders] = useState(null);
  const [isSelectingClient, setIsSelectingClient] = useState({ isSelecting: false, dataItem: null });
  const [clients, setClients] = useState(null);

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );


  let getAllClients = useCallback(async (e) => {
    let init = { method: 'GET', accept: 'application/json', headers: headers };
    let url = GATEWAY_BASE + '/multitenancy/api/Clients/Clients';

    await fetch(url, init)
      .then(response => response.json())
      .then(json => {
        setClients(json.filter(clients => clients.isActive));
      });
  }, [headers]);

  useEffect(() => {
    if (state.headersLoaded === true) {
      if (clients === null || clients.length === 0 || state.rebindStatus) {
        getAllClients();
      }
    }
  }, [state.headersLoaded, state.rebindStatus, clients, getAllClients]);

  useEffect(() => {
    if (clients !== null){
      setState(s => ({ ...s, isLoading: false, rebindStatus: false }));
    }
  }, [clients]);

  useEffect(() => {
    if (isSelectingClient.dataItem !== null && isSelectingClient.isSelecting === true) {
      let authContext = context;
      let url =
        GATEWAY_BASE +
        "/multitenancy/api/Clients/" +
        isSelectingClient.dataItem.clientGuid +
        "/Products?all=true";

      fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 204) {
            return [];
          } else {
            return res.json();
          }
        })
        .then(
          (result) => {
            let products = result;

            if (typeof products !== "undefined") {
              setState(s => ({
                ...s,
                selectedClient: {
                  name: isSelectingClient.dataItem.name,
                  isActive: isSelectingClient.dataItem.isActive,
                  clientGuid: isSelectingClient.dataItem.clientGuid,
                  products: products,
                  dtcNumber: isSelectingClient.dataItem.dtcNumber
                },
                manageClientProduct: false,
              }));

              setIsSelectingClient({ ...isSelectingClient, isSelecting: false, dataItem: null });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }, [context, isSelectingClient]);

  useEffect(() => {
    if (headers !== null && typeof (headers['Authorization']) != 'undefined') {
      setState(s => ({
        ...s,
        authLoaded: true,
        headersLoaded: true
      }));
    }
  }, [headers]);

  useEffect(() => {
    let authContext = context;

    let checkForUser = setInterval(() => {
      if (authContext.isLoaded() && authContext.authState()) {
        setHeaders({
          Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        });

        clearInterval(checkForUser);
      }
    }, 100);
  }, [context]);

  const displayClientForm = (clientGuid, display) => {
    let authContext = context;

    if (clientGuid) {
      fetch(GATEWAY_BASE + "/multitenancy/api/clients/" + clientGuid, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setState({
              ...state,
              displayClientForm: display,
              isLoaded: false,
              selectedClient: {
                name: result.name,
                isActive: result.isActive,
                clientGuid: result.clientGuid,
              },
            });
          },
          (error) => {
            setState({
              ...state,
              isLoaded: false,
              error,
            });
          }
        );
    } else {
      setState({
        ...state,
        displayClientForm: display,
        selectedClient: display ? {} : null,
      });
    }
  };

  const afterSave = () => {
    setState({ ...state, displayClientFormSaveSuccess: true, displayClientForm: false, rebindStatus: true });
  };

  const selectClientProduct = (selectedProduct) => {
    setState({
      ...state,
      manageClientProduct: true,
      selectedProduct: selectedProduct,
    });
  };

  const backToClientManagement = () => {
    setState({ ...state, manageClientProduct: false, selectedProduct: null, isLoaded: false });
  };

  const selectClient = (dataItem) => {
    setIsSelectingClient({ ...isSelectingClient, isSelecting: true, dataItem: dataItem });
  };


  let clientCell = (value) => {
    return (
      <td
        style={{ cursor: "pointer" }}
        onClick={() => selectClient(value.dataItem)}
      >
        {value.dataItem.name}
      </td>
    );
  };

  let clientHeaderName = () => {
    return (
      <span>
        Clients
      </span>
    );
  };

  return (
    <div className="relative-div">
      {(state.authLoaded && state.headersLoaded) ? (
        <div>
          <div className={styles.clientManagementActions}>
            <LButton
              themeColor="primary"
              className={styles.addNewClientButton + " green"}
              onClick={() => displayClientForm(null, true)}
              tooltipPosition="bottomLeft"
              tooltip="Add New Client"
            ><FontAwesomeIcon icon="plus" /> Add New Client</LButton>
          </div>
          <div>
            <div className={styles.loffaClientTableContainer}>
              {state.isLoading && loadingPanel}
              <LGrid
                className={styles.saveForm}
                sortable
                filterable
                rowSelectionHighlight={true}
                data={clients}
                columns={[
                  {
                    field: "name",
                    title: clientHeaderName(),
                    width: "370px",
                    cell: clientCell,
                  },
                ]}
              ></LGrid>
            </div>
            <div className={styles.loffaClientManagement}>
              {isSelectingClient.isSelecting && loadingPanel}
              {!state.selectedClient && (
                <div className={styles.selectClientContainer}>
                  <p>
                    <FontAwesomeIcon icon="fa-left-long" /> Please select a client.
                  </p>
                </div>
              )}
              {state.selectedClient && !state.displayClientForm && (
                <div>
                  {state.manageClientProduct && (
                    <ClientProductManagement
                      selectedClient={state.selectedClient}
                      selectedProduct={state.selectedProduct}
                      onBackClick={backToClientManagement}
                    ></ClientProductManagement>
                  )}
                  {!state.manageClientProduct && (
                    // TODO: Move this into it's own component (rename clientManagement?)
                    <div>
                      <div className={styles.clientNameHeader}>
                        <div className={styles.clientName}>
                          Client
                        </div>
                        <div className={styles.clientProduct}>
                          {state.selectedClient.name}
                        </div>
                      </div>
                      <LTabStrip
                        tabs={[
                          {
                            title: "Products",
                            body: (
                              <ClientProducts
                                rebind={selectClient}
                                selectedClient={state.selectedClient}
                                data={state.selectedClient.products}
                                onManageClientProductClick={
                                  selectClientProduct
                                }
                              />
                            ),
                          },
                          {
                            title: "Info",
                            body: (
                              <ClientProfile
                                clientGuid={state.selectedClient.clientGuid}
                                key={state.selectedClient.clientGuid}
                                refreshList={getAllClients}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div>
            <LNotificationGroup>
              <LFade>
                {state.displayClientFormSaveSuccess && (
                  <LNotification
                    type={{ style: "success", icon: true }}
                    closable={true}
                    onClose={() =>
                      setState({ displayClientFormSaveSuccess: false })
                    }
                  >
                    <span>Client saved.</span>
                  </LNotification>
                )}
              </LFade>
            </LNotificationGroup>
            <LFade transitionEnterDuration={200}>
              {state.displayClientForm && (
                <ClientForm
                  selectedClient={state.selectedClient}
                  closeForm={() => displayClientForm(null, false)}
                  saveForm={() => afterSave()}
                ></ClientForm>
              )}
            </LFade>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export { ClientTable };
