import React, { useContext, useState, useEffect, useCallback } from "react";
import { LGrid, LFormInput } from "@loffa/gpp-modules";
import { AuthContext } from "../../../../../../../../../context/AuthContext";
import NotificationContext from "../../../../../../../../../context/NotificationContext";
import styles from "./CPUPermissions.module.css";
import { isEmpty } from "lodash";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

export function CPUPP_PBINConfig({ updatePermissions, user, clientGuid, setParentLoading, saveForm, closeForm, productGuid }) {
  const context = useContext(AuthContext);
  const [state, setState] = useState({
    dtcs: [],
    accountTypes: [],
    roles: [],
    modules: [],
    displayModules: false,
    selectedRoleTypeId: 0,
    clientGuid: clientGuid,
    productGuid: productGuid,
    baseProductUrl: ""
  });
  const [hasDataBound, setHasDataBound] = useState(false);
  const [isGettingPermissions, setIsGettingPermissions] = useState(false);
  const notificationContext = useContext(NotificationContext);

  const getPermissions = useCallback((parentResponse) => {
    setParentLoading(true);

    let productPermissionUrl =
      parentResponse.url + "/api/Multitenancy/GetPermissionTypes";

    fetch(productPermissionUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
        "Content-Type": "application/json",
        ClientGuid: state.clientGuid,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(function (response) {
        let existingState = {};

        if ('undefined' != typeof (response.modules)) {
          existingState = {
            displayModules: true,
            modules: response.modules.map((obj) => ({ ...obj, selected: false }))
          };
        }
        else {
          existingState = {
            displayModules: false
          };
        }

        setState(s => ({
          ...s,
          ...existingState,
          dtcs: response.dtcs.map((obj) => ({ ...obj, selected: false })),
          accountTypes: response.accountTypes.map((obj) => ({
            ...obj,
            selected: false,
          })),
          roles: response.roles,
          baseProductUrl: parentResponse.url
        }));

        setHasDataBound(true);

        if (user.userGuid) {
          setIsGettingPermissions(true);
        }

        setParentLoading(false);
      })
      .catch((error) => {
        setParentLoading(false);
        closeForm();
        notificationContext.addError("Could not load product permissions. Verify product configuration.");
      });
  }, [setParentLoading, setIsGettingPermissions, user.userGuid, state.clientGuid, closeForm, context, notificationContext]);

  useEffect(() => {
    if (hasDataBound) {
      updatePermissions({
        accountTypeIds: state.accountTypes
          .filter((obj) => obj.selected)
          .map((obj) => obj.id),
        dtcIds: state.dtcs
          .filter((obj) => obj.selected)
          .map((obj) => obj.id),
        productRoleTypeId: state.selectedRoleTypeId,
        moduleIds: state.modules
          .filter((obj) => obj.selected)
          .map((obj) => obj.id),
      });
    }
  }, [updatePermissions, hasDataBound, state.accountTypes, state.dtcs, state.modules, state.selectedRoleTypeId]);

  const bindData = useCallback(() => {
    setParentLoading(true);

    let productUrl =
      GATEWAY_BASE + "/multitenancy/api/products/" + state.productGuid;

    fetch(productUrl, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(function (response) {
        getPermissions(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getPermissions, setParentLoading, context, state.productGuid]);

  const getExistingPermissions = useCallback(() => {
    setParentLoading(true);

    let existingProductPermissionUrl =
      state.baseProductUrl +
      "/api/Multitenancy/GetExistingUserPermissions?oktaId=" +
      user.oktaId;

    fetch(existingProductPermissionUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
        "Content-Type": "application/json",
        ClientGuid: state.clientGuid,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(function (response) {
        if (isEmpty(response)) {
          setParentLoading(false);
          return;
        }

        // set accountTypes
        let accountTypes = state.accountTypes;
        accountTypes.forEach((element, index) => {
          let responseAccountTypes = response.accountTypes;
          let matchedResponseAccountType = responseAccountTypes.find(
            (a) => a.id === element.id
          );

          if (matchedResponseAccountType != null) {
            matchedResponseAccountType.selected = true;
            accountTypes[index] = matchedResponseAccountType;
          }
        });

        // set dtcs
        let dtcs = state.dtcs;
        dtcs.forEach((element, index) => {
          let responseDTCs = response.dtcs;
          let matchedResponseDTC = responseDTCs.find(
            (a) => a.id === element.id
          );

          if (matchedResponseDTC != null) {
            matchedResponseDTC.selected = true;
            dtcs[index] = matchedResponseDTC;
          }
        });


        // set modules
        let modules = state.modules;
        modules.forEach((element, index) => {
          let responseModules = response.modules;
          let matchedResponseModules = responseModules.find(
            (a) => a.id === element.id
          );

          if (matchedResponseModules != null) {
            matchedResponseModules.selected = true;
            modules[index] = matchedResponseModules;
          }
        });

        // set roles
        setState(s =>
        ({
          ...s,
          accountTypes: accountTypes,
          dtcs: dtcs,
          selectedRoleTypeId: response.roles.id
        }));

        setParentLoading(false);
      });
  }, [setParentLoading, context, user.oktaId, state.baseProductUrl, state.accountTypes, state.clientGuid, state.dtcs, state.modules]);

  useEffect(() => {
    if (isGettingPermissions) {
      getExistingPermissions();
      setIsGettingPermissions(false);
    }
  }, [isGettingPermissions, getExistingPermissions]);

  useEffect(() => {
    if (!hasDataBound) {
      bindData();
    }
  }, [bindData, hasDataBound]);


  const accountTypeSelectionChange = (event) => {
    const data = state.accountTypes.map((item) => {
      if (item.id === event.dataItem.id) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    setState(s => ({
      ...s, accountTypes: data
    }));
  };

  const accountTypeHeaderSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const data = state.accountTypes.map((item) => {
      item.selected = checked;
      return item;
    });
    setState(s => ({
      ...s, accountTypes: data
    }));
  };


  const modulesSelectionChange = (event) => {
    const data = state.modules.map((item) => {
      if (item.id === event.dataItem.id) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    setState(s => ({
      ...s, modules: data
    }));
  };

  const modulesHeaderSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const data = state.modules.map((item) => {
      item.selected = checked;
      return item;
    });
    setState(s => ({
      ...s, modules: data
    }));
  };

  const dtcSelectionChange = (event) => {
    const data = state.dtcs.map((item) => {
      if (item.id === event.dataItem.id) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    setState(s => ({
      ...s, dtcs: data
    }));
  };

  const dtcHeaderSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const data = state.dtcs.map((item) => {
      item.selected = checked;
      return item;
    });
    setState(s => ({
      ...state, dtcs: data
    }));
  };

  const selectRole = (e) => {
    setState(s =>
    ({
      ...state,
      selectedRoleTypeId: e.value?.id,
    }));
  };

  return (
    <div className="relative-div">
      <div className={styles.permissionInput}>
        <LFormInput
          labelName="Role *"
          type="combobox"
          comboBox={{
            data: state.roles,
            defaultValue: state.roles.find(
              (obj) => obj.id === state.selectedRoleTypeId
            ),
            textField: "name",
            dataItemKey: "id",
            onChange: (e) => selectRole(e),
          }}
        />
      </div>
      <div className={styles.permissionInput}>
        <label className={styles.permissionLabel}>Account Types</label>
        <div className={styles.permissionGrid}>
          <LGrid
            style={{
              height: "200px",
            }}
            sortable
            filterable={false}
            pageable={false}
            selectedField="selected"
            onSelectionChange={accountTypeSelectionChange}
            onHeaderSelectionChange={accountTypeHeaderSelectionChange}
            data={state.accountTypes}
            columns={[
              {
                field: "selected",
                width: "50px",
                filterable: false,
              },
              {
                field: "accountTypeName",
                title: "Account Type Name",
                width: "250px",
              },
              {
                field: "accountTypeAbbreviation",
                title: "Account Type Abbr.",
              },
            ]}
          ></LGrid>
        </div>
      </div>
      <div className={styles.permissionInput}>
        <label className={styles.permissionLabel}>DTCs</label>
        <div className={styles.permissionGrid}>
          <LGrid
            style={{
              height: "200px",
            }}
            sortable
            filterable
            pageable={false}
            data={state.dtcs}
            selectedField="selected"
            onSelectionChange={dtcSelectionChange}
            onHeaderSelectionChange={dtcHeaderSelectionChange}
            columns={[
              {
                field: "selected",
                width: "50px",
                filterable: false,
              },
              {
                field: "dtc",
                title: "DTC #",
                width: "75px",
              },
              {
                field: "name",
                title: "DTC Name",
              },
              {
                field: "mpid",
                title: "DTC MPID",
                width: "100px",
              },
            ]}
          ></LGrid>
        </div>
      </div>
      {state.displayModules &&
        <div className={styles.permissionInput}>
          <label className={styles.permissionLabel}>Module Types</label>
          <div className={styles.permissionGrid}>
            <LGrid
              sortable
              filterable={false}
              pageable={false}
              selectedField="selected"
              onSelectionChange={modulesSelectionChange}
              onHeaderSelectionChange={modulesHeaderSelectionChange}
              data={state.modules}
              columns={[
                {
                  field: "selected",
                  width: "50px",
                  filterable: false,
                },
                {
                  field: "name",
                  title: "Module Name",
                  width: "250px",
                }
              ]}
            ></LGrid>
          </div>
        </div>
      }
    </div>
  );
}
