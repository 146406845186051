import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  LButton,
  LFade,
  LGrid
} from "@loffa/gpp-modules";

import { AuthContext } from "../../../../../context/AuthContext";
import NotificationContext from "../../../../../context/NotificationContext";
import { UserForm } from "../UserForm/UserForm";
import styles from "./UserTable.module.css";
import UserTableOktaStatusCell from "./UserTableOktaStatusCell/UserTableOktaStatusCell";
import KeyBasedLButton from "../../../../Shared/KeyBasedLButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function UserTable() {
  const [state, setState] = useState({
    rebindStatus: true,
    headers: null,
    usersLoaded: false
  });

  const [userFormData, setUserFormData] = useState({
    displayUserForm: false,
    selectedUser: {}
  });

  useContext(NotificationContext);
  const authContext = useContext(AuthContext);
  const [isActiveOnly, setIsActiveOnly] = useState(false);
  const [exportProps, setExportProps] = useState({ data: null, isExporting: false });
  const [users, setUsers] = useState([]);

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const getDataSourceUrl = useCallback(() => {
    let base = GATEWAY_BASE;

    if (isActiveOnly) {
      return base + "/multitenancy/api/Users/Users?&isactive=true";
    }
    else {
      return base + "/multitenancy/api/Users/Users";
    }
  }, [isActiveOnly]);

  let getAllRecords = useCallback(async (e) => {
    let init = { method: 'GET', accept: 'application/json', headers: state.headers };
    let url = getDataSourceUrl();

    await fetch(url, init)
      .then(response => response.json())
      .then(json => {
        setUsers(json);
      });
  }, [getDataSourceUrl, state.headers]);

  useEffect(() => {
    if (state.headers !== null) {
      if (users.length === 0 || state.rebindStatus) {
        getAllRecords();
      }
    }
  }, [state.headers, state.rebindStatus, users.length, getAllRecords]);

  useEffect(() => {
    if (users.length > 0) {
      setState(s => ({ ...s, usersLoaded: true, rebindStatus: false }))
    }
  }, [users]);

  useEffect(() => {
    setState(s => ({ ...s, rebindStatus: true }))
  }, [isActiveOnly]);

  useEffect(() => {
    let checkForUser = setInterval(() => {
      if (authContext.isLoaded() && authContext.authState()) {

        setState(s => ({
          ...s,
          headers: {
            Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
          }
        }));

        clearInterval(checkForUser);
      }
    }, 100);
  }, [authContext]);

  const displayUserForm = (userGuid, display) => {
    if (userGuid) {
      fetch(GATEWAY_BASE + "/multitenancy/api/users/" + userGuid, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setUserFormData({
              displayUserForm: display,
              selectedUser: {
                firstName: result.firstName,
                lastName: result.lastName,
                email: result.email,
                roleTypeId: result.roleTypeId,
                isActive: result.isActive,
                userGuid: result.userGuid,
              },
            });
          },
          (error) => {
            setState({
              ...state,
              isLoaded: false,
              error,
            });
          }
        );
    } else {
      setUserFormData({
        displayUserForm: display,
        selectedUser: null,
      });
    }
  };

  const afterSave = () => {
    setUserFormData({
      ...userFormData,
      displayUserForm: false
    });
    setState({ ...state, rebindStatus: true });
  };

  const failedSave = () => {
    setUserFormData({
      ...userFormData,
      displayUserForm: true
    });
    setState({ ...state, rebindStatus: true });
  };

  let onExport = async () => {
    setExportProps({ data: users, isExporting: true });
  };

  let exportCallback = (e) => {
    setExportProps({ ...exportProps, isExporting: false });
  };

  const editCell = (value) => {
    return (<td><KeyBasedLButton
      onClick={() => displayUserForm(value.dataItem.userGuid, true)}
      parentKey={value.dataItem.userGuid}
      buttonText="Edit"></KeyBasedLButton></td>);
  };

  const oktaStatusCell = (value) => {
    return (<td><UserTableOktaStatusCell userGuid={value.dataItem.userGuid} userEmail={value.dataItem.email} oktaId={value.dataItem.oktaId}></UserTableOktaStatusCell></td>);
  }

  const isActiveCell = (value) => {
    return (
      <td>
        {value.dataItem.isActive ? (<span className={styles.active}>Active</span>) : (<span className={styles.inactive}>Not Active</span>)}
      </td>
    );
  };

  return (
    <div className="relative-div">
      <div>
        <div className={styles.actionContainer}>
          <div className="ac-left-action">
            <KeyBasedLButton
              themeColor="primary"
              className="green"
              onClick={() => displayUserForm(null, true)}
              parentKey={users.userGuid}
              buttonText={"Add New User"}
              icon={<FontAwesomeIcon icon="plus" />}
            >
            </KeyBasedLButton>
          </div>
          <div>
            {state.usersLoaded &&
              <LButton onClick={onExport}>Export All</LButton>
            }
            {
              isActiveOnly &&
              <LButton
                className="blue"
                onClick={() => setIsActiveOnly(false)}
              >
                View All Users
              </LButton>
            }
            {
              !isActiveOnly &&
              <LButton
                className="blue"
                onClick={() => setIsActiveOnly(true)}
              >
                View Active Users Only
              </LButton>
            }
          </div>
        </div>
        <div>
          {!state.usersLoaded && loadingPanel}

          {
            (<LGrid
              className={styles.showOverflow}
              exportStatus={exportProps.isExporting}
              exportCallback={exportCallback}
              exportData={exportProps.data}
              sortable
              filterable
              data={users}
              columns={[
                {
                  field: "userGuid",
                  title: " ",
                  cell: editCell,
                  filterable: false,
                  sortable: false,
                  width: "100px",
                },
                {
                  field: "firstName",
                  title: "FirstName",
                  width: "200px",
                },
                {
                  field: "lastName",
                  title: "LastName",
                  width: "200px",
                },
                {
                  field: "email",
                  title: "Email",
                },
                {
                  field: "roleTypeName",
                  title: "Role",
                  width: "150px",
                },
                {
                  field: "isActive",
                  title: "Active",
                  cell: isActiveCell,
                  width: "100px",
                  filter: "boolean"
                },
                {
                  field: "oktaStatus",
                  title: "Okta Status",
                  cell: oktaStatusCell,
                  filterable: false,
                  sortable: false,
                  width: "200px",
                }
              ]}
            ></LGrid>)}
        </div>
        <LFade transitionEnterDuration={200}>
          {userFormData.displayUserForm && (
            <UserForm
              selectedUser={userFormData.selectedUser}
              isAdmin={true}
              closeForm={() => displayUserForm(null, false)}
              saveForm={() => afterSave()}
              failedForm={() => failedSave()}
            ></UserForm>
          )}
        </LFade>
      </div>
    </div>
  );
}

export { UserTable }