import React, { useState, useContext, useEffect, useCallback } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import styles from "./ChangePassword.module.css";
import { LButton, LFormInput } from "@loffa/gpp-modules";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const urlParams = new URLSearchParams(window.location.search);

export function ChangePassword(props) {
  const context = useContext(AuthContext);
  const [state, setState] = useState({
    newPasswordMatch: true,
    showResponseMessage: false,
    responseMessageIsSuccess: false,
    responseMessage: "",
    redirectUrl: '',
    styles: {},
  });

  const [currentPassword, setCurrentPassword] = useState({ password: '', isValid: false, needValidation: true });
  const [newPassword, setNewPassword] = useState({ password: '', isValid: false, needValidation: true });
  const [newPasswordConfirm, setNewPasswordConfirm] = useState({ password: '', isValid: false, needValidation: true });
  const [clientValidation, setClientValidation] = useState({});

  const validateInput = useCallback((regex) => {
    let isMatch = regex.test(newPassword.password);

    return isMatch;
  }, [newPassword.password]);

  const redirect = useCallback(() => {
    window.location.replace(state.redirectUrl);
  }, [state]);

  const validateNewPassword = useCallback(() => {
    let authContext = context;
    var currentValidationState = { ...clientValidation };

    currentValidationState.hasUppercase = newPassword.password !== '' && validateInput(
      /[A-Z]+/,
      "hasUppercase"
    );
    currentValidationState.hasLowercase = newPassword.password !== '' && validateInput(
      /[a-z]+/,
      "hasLowercase"
    );
    currentValidationState.hasNumber = newPassword.password !== '' && validateInput(
      /[0-9]+/,
      "hasNumber"
    );
    currentValidationState.hasEightCharacters = newPassword.password !== '' && !validateInput(
      /^.{1,7}$/,
      "hasEightCharacters"
    );

    let pattern = "^([" + authContext.userInfo().email + "]){4}";

    var re = new RegExp(pattern);
    currentValidationState.hasNoUsernameMatch = !validateInput(
      re,
      "hasNoUsernameMatch"
    );

    setClientValidation(currentValidationState);
  }, [clientValidation, newPassword.password, context, validateInput]);

  const validateNewConfirmPassword = useCallback(() => {
    if (newPassword.password !== newPasswordConfirm.password) {
      setNewPasswordConfirm(p => ({ ...p, isValid: false, needValidation: false }));
    } else {
      setNewPasswordConfirm(p => ({ ...p, isValid: true, needValidation: false }));
    }
  }, [setNewPasswordConfirm, newPassword.password, newPasswordConfirm.password]);

  const checkValidation = useCallback((key) => {
    if (currentPassword === '') {
      return false;
    }

    return clientValidation[key];
  }, [currentPassword, clientValidation]);

  useEffect(() => {
    let redirectUrl = urlParams.get('r');
    if (redirectUrl) {
      setState(s => ({ ...s, redirectUrl: redirectUrl }))
    };
  }, []);

  useEffect(() => {
    if (newPassword.needValidation === true) {
      validateNewPassword();
      validateNewConfirmPassword();
    }

  }, [newPassword, validateNewPassword, validateNewConfirmPassword]);

  useEffect(() => {
    if (newPasswordConfirm.needValidation === true) {
      validateNewConfirmPassword();
    }
  }, [newPasswordConfirm, validateNewConfirmPassword]);

  useEffect(() => {
    if (Object.keys(clientValidation).every(checkValidation)) {
      setNewPassword(p => ({ ...p, isValid: true, needValidation: false }));
    }
    else {
      setNewPassword(p => ({ ...p, isValid: false, needValidation: false }));
    }
  }, [clientValidation, checkValidation]);

  useEffect(() => {
    if (state.responseMessageIsSuccess === true) {
      redirect();
    }
  }, [state.responseMessageIsSuccess, redirect]);

  const handleOldPasswordStateChange = (e) => {
    let isPasswordValid = true;

    if (e.target.value === '') {
      isPasswordValid = false;
    }
    setCurrentPassword({ password: e.target.value, isValid: isPasswordValid, needValidation: true });
  };

  const handleNewPasswordStateChange = (e) => {
    let isPasswordValid = true;

    if (e.target.value === '') {
      isPasswordValid = false;
    }

    setNewPassword({ password: e.target.value, isValid: isPasswordValid, needValidation: true });
  };

  const handleNewPasswordConfirmStateChange = (e) => {
    setNewPasswordConfirm({ password: e.target.value, isValid: false, needValidation: true });
  };

  let changePassword = (e) => {
    let authContext = context;

    setState({
      ...state,
      showResponseMessage: false,
      responseMessage: "",
    });

    fetch(
      GATEWAY_BASE +
      "/identity/api/users/byoktaid/" +
      authContext.userInfo().sub +
      "/changepassword",
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${authContext.authState().accessToken.accessToken
            }`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          OldPassword: currentPassword.password,
          NewPassword: newPassword.password,
        })
      }
    )
      .then((res) => {
        if (res.ok) {
          setState({
            ...state,
            showResponseMessage: false, //this was true, but it flashed so fast it was unreadable and the red color looked like an error.
            responseMessageIsSuccess: true,
            responseMessage: "Successfully changed password. Redirecting back to application...",
          });
        }
        else {
          if (res.status == null || res.status === undefined) {
            throw res;
          }
          else {
            if (res.status != null && res.status !== undefined && res.status === 400) {
              return res.json();
            }
            else {
              throw res;
            }
          }
        }
      }).then(body => {
        throw (body);

      })
      .catch((error) => {
        setState({
          ...state,
          showResponseMessage: true,
          responseMessageIsSuccess: false,
          redirectUrl: "",
          responseMessage: getResponseMessage(error),
        });
      });
  };

  const getResponseMessage = (error) => {


    if (error != null && error !== undefined && error.errorCauses != null && error.errorCauses !== undefined) {
      return "Could not change password. Error: " + error.errorCauses.flatMap(a => a.errorSummary);
    }
    else if (error != null && error !== undefined && error.status != null && error.status !== undefined) {
      return "Count not change password. The error code detected was: " + error.status;
    }
    else if (error != null && error !== undefined && error.stack != null && error.stack !== undefined) {
      return "Count not change password. The error stack is: " + error.stack;
    }
    else {
      return "Could not change password. There was an unknown error that prevented the password from being saved. No exception data was passed.";
    }
  };

  return (
    <div className={styles.changePasswordContainer}>
      {state.showResponseMessage && (
        <div className={styles.responseMessage + " " + (state.responseMessageIsSuccess ? styles.successMessage : styles.errorMessage)}>
          <p>{state.responseMessage}</p>
        </div>
      )}
      <div>
        <LFormInput
          labelName="Current Password *"
          type="input"
          onChange={(event) => handleOldPasswordStateChange(event)}
          input={{
            value: state.currentPassword,
            onChange: (event) => handleOldPasswordStateChange(event),
            name: "currentPassword",
            required: true,
            type: "password",
          }}
        />
        <hr />
        <LFormInput
          labelName="New Password *"
          type="input"
          onChange={(event) => handleNewPasswordStateChange(event)}
          input={{
            value: state.newPassword,
            onChange: (event) => handleNewPasswordStateChange(event),
            name: "newPassword",
            required: true,
            type: "password",
          }}
        />

        <LFormInput
          labelName="Confirm New Password *"
          type="input"
          onChange={(event) => handleNewPasswordConfirmStateChange(event)}
          input={{
            value: state.newPasswordConfirm,
            onChange: (event) => handleNewPasswordConfirmStateChange(event),
            name: "newPasswordConfirm",
            required: true,
            type: "password",
          }}
        />



        <div className={styles.requirementsList}>
          <ul>
            <li>
              <span
                className={
                  clientValidation.hasEightCharacters
                    ? styles.isValid
                    : styles.isNotValid
                }
              >
                Has at least 8 characters
              </span>
            </li>
            <li>
              <span
                className={
                  clientValidation.hasUppercase
                    ? styles.isValid
                    : styles.isNotValid
                }
              >
                Must contain 1 upper case letter
              </span>
            </li>
            <li>
              <span
                className={
                  clientValidation.hasLowercase
                    ? styles.isValid
                    : styles.isNotValid
                }
              >
                Must contain 1 lower case letter
              </span>
            </li>
            <li>
              <span
                className={
                  clientValidation.hasNumber
                    ? styles.isValid
                    : styles.isNotValid
                }
              >
                Must contain a number
              </span>
            </li>
            <li>
              <span
                className={
                  clientValidation.hasNoUsernameMatch
                    ? styles.isValid
                    : styles.isNotValid
                }
              >
                Must not contain part of email (4 matching consecutive
                characters)
              </span>
            </li>
          </ul>
        </div>
        {!state.newPasswordMatch && (
          <div className={styles.errorMatch}>New passwords do not match.</div>
        )}

        <div className={styles.buttonContainer}>
          {(currentPassword.isValid && newPassword.isValid && newPasswordConfirm.isValid) &&
            <LButton
              themeColor="primary"
              className="green"
              type="submit"
              onClick={changePassword}
            >
              Change Password
            </LButton>
          }
          {!(currentPassword.isValid && newPassword.isValid && newPasswordConfirm.isValid) &&
            <LButton
              themeColor="primary"
              className="green"
              type="submit"
              disabled={true}
            >
              Change Password
            </LButton>
          }
        </div>
      </div>
    </div>
  );
}