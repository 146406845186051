import React, { useState } from "react";

export const AuthContext = React.createContext();

export function AuthProvider(props) {
  const [state, setState] = useState({
    isLoaded: false,
    userInfo: "",
    token: null,
    authState: null
  });

  const authState = () => {
    return state.authState;
  };

  const setAuthState = (value) => {
    setState({
      ...state,
      authState: value
    });
  };

  const setUserInfo = (value,) => {
    setState({
      ...state,
      userInfo: value
    });
  };

  const userInfo = () => {
    return state.userInfo;
  };

  const setIsLoaded = (value) => {
    setState({
      ...state,
      isLoaded: value
    });
  };

  const getAuthContextState = () => {
    return state;
  };

  const setAuthContextState = (authContextState) => {
    setState(
      {
        ...state,
        authState: authContextState.authState,
        isLoaded: authContextState.isLoaded,
        userInfo: authContextState.userInfo
      });
  };

  const isLoaded = () => {
    return state.isLoaded;
  }

  return (
    <AuthContext.Provider
      value={{
        authState: authState,
        setAuthState: setAuthState,
        userInfo: userInfo,
        setUserInfo: setUserInfo,
        setIsLoaded: setIsLoaded,
        isLoaded: isLoaded,
        getAuthContextState: getAuthContextState,
        setAuthContextState: setAuthContextState
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}