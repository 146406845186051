import React from "react";
import { Route, useNavigate, Routes, Navigate } from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import Login from "../Login/Login";
import JITUserCreationView from "../Login/JITUserCreationView/JITUserCreationView";
import Logout from "../Logout/Logout";
import AuthenticatedApp from "../AuthenticatedApp/AuthenticatedApp";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import PrivateRoute from '../Routing/PrivateRoute';

const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const OKTA_AUTHZ = process.env.REACT_APP_OKTA_AUTHZ_PATH;
const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_SSO_CLIENT_ID;
const oktaAuth = new OktaAuth({
  issuer: "https://" + OKTA_DOMAIN + OKTA_AUTHZ,
  clientId: OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/implicit/callback",
  tokenManager: {
    autoRenew: true,
    storage: 'sessionStorage'
  }
});

export default function Routing() {
  let navigate = useNavigate();

  let onAuthRequired = () => {
    navigate.push("/login");
  };

  let restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true })
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Routes>
        <Route path="implicit/callback" element={<LoginCallback />} />
        <Route
          path="login"
          element={<Login baseUrl={"https://" + OKTA_DOMAIN} />}
        />
        <Route path="logout" element={<PrivateRoute />}>
          <Route path="logout" element={<Logout />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Navigate to="/dashboard/products" />} />
        </Route>
        <Route path="dashboard" element={<PrivateRoute />}>
          <Route path="*" element={<AuthenticatedApp />} />
        </Route>
        <Route
          path="/signin/reset-password/*"
          element={
            <Login
              baseUrl={"https://" + OKTA_DOMAIN + "/signin/reset-password/"}
            />
          }
        />
        <Route
          path="samlredirect"
          element={<JITUserCreationView />}
        />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </Security>
  );
};
