import React, { useState, useContext } from "react";
import { LButton, LFormInput, LDialog } from "@loffa/gpp-modules";
import { AuthContext } from "../../../../../../context/AuthContext";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function ClientForm(props) {
  const context = useContext(AuthContext);
  const [state, setState] = useState({
    client: props.selectedClient,
    saveSuccessful: false,
  });

  const handleStateChange = (e) => {
    setState({
      ...state,
      client: {
        ...state.client,
        [e.target.name]: e.target.value,
      },
    });
  };

  const setDialogTitle = () => {
    return "Add New Client";
  };

  const handleSubmit = (e) => {
    e.currentTarget.checkValidity();
    e.preventDefault();
    let authContext = context;
    let method = "POST";
    let url = GATEWAY_BASE + "/multitenancy/api/Clients";

    fetch(url, {
      method: method,
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(state.client),
    }).then(
      (result) => {
        if (result.ok) {
          props.saveForm();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <LDialog
          key={state.client.clientGuid}
          width="500"
          title={setDialogTitle()}
          body={
            <div>
              <div className="required-field">* required</div>
              <LFormInput
                labelName="Name *"
                type="input"
                onChange={(event) => handleStateChange(event)}
                input={{
                  value: state.client.name,
                  onChange: (event) => handleStateChange(event),
                  name: "name",
                  required: true,
                }}
              />
            </div>
          }
          actionLayout="end"
          action={
            <React.Fragment>
              <LButton onClick={props.closeForm}>Cancel</LButton>
              <LButton themeColor="primary" className="green" type="submit">
                Save
              </LButton>
            </React.Fragment>
          }
          actionCloseButton={props.closeForm}
          actionOverlay={props.closeForm}
        />
      </form>
    </div>
  );
}

export { ClientForm };
