import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  LButton,
  LGrid,
  LFade,
  LNotification,
  LNotificationGroup,
  LSwitch,
  LDialog
} from "@loffa/gpp-modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../../../../../context/AuthContext";
import { ProductForm } from "../ProductForm/ProductForm";
import { CPConfigurationManagement } from "../../ClientManagement/CTable/CProductManagement/CPConfigManagement/CPConfigManagement";
import styles from "./ProductTable.module.css";
import KeyBasedLButton from "../../../../Shared/KeyBasedLButton"

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function ProductTable(props) {
  const context = useContext(AuthContext);

  const [state, setState] = useState({
    authLoaded: false,
    displayConfiguration: false,
    displayProductForm: false,
    displayProductFormSaveSuccess: false,
    selectedProduct: {},
    selectedProductGuid: {},
    rebindStatus: true,
    productsLoaded: false    
  });
  const [isLoading, setIsLoading] = useState(false);

  const [headers, setHeaders] = useState(null);
  const [products, setProducts] = useState([]);
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  let getAllProducts = useCallback(async (e) => {
    setIsLoading(true);
    let authContext = context;
    let headers = {
      Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
      "Content-Type": "application/json",
    };

    let init = { mode: "cors", headers: headers };
    let url = GATEWAY_BASE + '/multitenancy/api/Products';

    await fetch(url, init)
      .then(response => response.json())
      .then(json => {
        setProducts(json);
        setIsLoading(false);
      });
  }, [setIsLoading, setProducts, context]);


  useEffect(() => {
    if (state.rebindStatus === true) {
      setState(s => ({ ...s, rebindStatus: false, productsLoaded: true }))
    }
  }, [products, state.rebindStatus]);

  useEffect(() => {
    if (state.headersLoaded === true) {
      if ((products.length === 0) || state.rebindStatus) {
        getAllProducts();
      }
    }

  }, [state.headersLoaded, state.rebindStatus, products, getAllProducts]);

  useEffect(() => {
    let authContext = context;

    let checkForUser = setInterval(() => {
      if (authContext.isLoaded() && authContext.authState()) {
        setHeaders({
          Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        });

        setState(s => ({
          ...s,
          authLoaded: true,
        }));

        clearInterval(checkForUser);
      }
    }, 100);
  }, [context]);

  useEffect(() => {
    if (headers !== null && typeof (headers['Authorization']) != 'undefined') {
      setState(s => ({
        ...s,
        authLoaded: true,
        headersLoaded: true
      }));
    }
  }, [headers]);

  const displayProductForm = (productGuid, display) => {
    let authContext = context; 

    if (productGuid) {
      fetch(GATEWAY_BASE + "/multitenancy/api/products/" + productGuid, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setState({
              ...state,
              displayProductForm: display,
              selectedProduct: {
                name: result.name,
                subName: result.subName,
                description: result.description,
                isActive: result.isActive,
                url: result.url,
                version: result.version,
                productLogoFileName: result.productLogoFileName,
                releaseDate: result.releaseDate
                  ? new Date(result.releaseDate)
                  : null,
                productGuid: result.productGuid,
                permissionConfigurationTypeId:
                  result.permissionConfigurationTypeId,
                isAdminOnly: result.isAdminOnly,
                isMultiTenant: result.isMultiTenant,
                productTypeId: result.productTypeId
              },
            });
          },
          (error) => {
            setState({
              ...state,
              isLoaded: false,
              error,
            });
          }
        );
    } else {
      setState({
        ...state,
        displayProductForm: display,
        selectedProduct: {},
      });
    }
  };

  const afterSave = () => {
    setState({
      ...state,
      displayProductFormSaveSuccess: true,
      displayProductForm: false,
      rebindStatus: true,
      productsLoaded: false
    });
  };

  const afterConfigSave = () => {
    setState({
      ...state,
      displayProductFormSaveSuccess: true,
      displayConfiguration: false,
      rebindStatus: true,
      productsLoaded: false
    });
  };

  const rebind = () => {
    getAllProducts();
  };


  const toggleClientProduct = (e, selectedProduct) => {
    let authContext = context;
    let url = GATEWAY_BASE + "/multitenancy/api/Products";
    let product = selectedProduct;

    product.isActive = !selectedProduct.isActive;
    product.lastUpdatedBy = authContext.userInfo().loffaUserGuid;

    fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(product),
    })
      .then(function (response) {
        if (response.ok) {
          rebind();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const displayConfigurationForm = (productGuid, display) => {
    if (display) {
      setState({
        ...state, selectedProductGuid: productGuid, displayConfiguration: true
      });
    }
    else {
      setState({
        ...state, selectedProductGuid: null, displayConfiguration: false
      });
    }
  };

  let dateCell = (value) => {
    var formattedDate = value.dataItem.releaseDate
      ? new Date(value.dataItem.releaseDate)
      : null;
    return (
      <td>
        {formattedDate &&
          formattedDate.getMonth() +
          "/" +
          formattedDate.getDate() +
          "/" +
          formattedDate.getFullYear()}
      </td>
    );
  };

  let editCell = (value) => {
    return (
      <td>
        <KeyBasedLButton
          className="green"
          themeColor="primary"
          onClick={() =>
            displayProductForm(value.dataItem.productGuid, true)
          }
          buttonText="Edit"
          parentKey={value.dataItem.productGuid}
        >
        </KeyBasedLButton>
      </td>
    );
  };

  let configureCell = (value) => {
    return (
      <td className={styles.configurationCell}>
        {!value.dataItem.isMultiTenant && (
          <LButton
            className="green"
            themeColor="primary"
            onClick={() =>
              displayConfigurationForm(value.dataItem.productGuid, true)
            }
          >
            Configure
          </LButton>
        )}
        {value.dataItem.isMultiTenant && (
          <LButton
            className={styles.tooltipPosition}
            look="flat"
            onClick={null}
            tooltip="This product has multi-tenancy enabled. Please configure product through Client Management."
            tooltipPosition="bottomRight"
          ><FontAwesomeIcon icon="info-circle" /> </LButton>
        )}
      </td>
    );
  };

  let statusCell = (value) => {
    return (
      <td>
        <div>
          {value.dataItem.isActive !== null && (
            <LSwitch
              width={100}
              id={value.dataItem.productGuid}
              onChange={(e) => toggleClientProduct(e, value.dataItem)}
              onLabel={"Enabled"}
              offLabel={"Disabled"}
              defaultChecked={value.dataItem.isActive}
            ></LSwitch>
          )}
        </div>
      </td>
    );
  };

  return (
    <div className="relative-div">
      {isLoading && (
        <div className="k-loading-mask">
          <span className="k-loading-text">Loading</span>
          <div className="k-loading-image"></div>
          <div className="k-loading-color"></div>
        </div>)}
      {state.authLoaded ? (
        <div>
          <div className={styles.productManagementActions}>
            <LButton
              themeColor="primary"
              className="green"
              onClick={() => displayProductForm(null, true)}
            >
              <FontAwesomeIcon icon="plus" /> Add New Product
            </LButton>
          </div>
          {!state.productsLoaded && loadingPanel}
          <LGrid
            style={{ height: "500px" }}
            data={products}
            sortable
            filterable
            pageable
            columns={[
              { field: "name", title: "Name", width: "300px" },
              { field: "subName", title: "Sub Name", width: "200px" },
              { field: "url", title: "URL", width: "200px" },
              {
                field: "description",
                title: "Description",
                width: "300px",
                filterable: false,
              },
              {
                field: "releaseDate",
                title: "Release Date",
                cell: dateCell,
                width: "120px",
                filterable: false,
              },
              {
                field: "version",
                title: "Version",
                width: "150px",
                filterable: false,
              },
              {
                field: "isActive",
                title: "Status",
                cell: statusCell,
                width: "120px",
                filterable: false,
              },
              {
                field: "productGuid",
                title: "Details",
                cell: editCell,
                filterable: false,
                sortable: false,
                width: "100px",
              },
              {
                field: "productGuid",
                title: "Configuration",
                cell: configureCell,
                filterable: false,
                sortable: false,
                width: "120px",
              },
              {
                field: "productGuid",
                title: "Product GUID",
                width: "250px",
                filterable: false,
              },
            ]}
          ></LGrid>
          <LNotificationGroup>
            <LFade>
              {state.displayProductFormSaveSuccess && (
                <LNotification
                  type={{ style: "success", icon: true }}
                  closable={true}
                  onClose={() =>
                    setState({ displayProductFormSaveSuccess: false })
                  }
                >
                  <span>Product saved.</span>
                </LNotification>
              )}
            </LFade>
          </LNotificationGroup>
          <LFade transitionEnterDuration={200}>
            {state.displayProductForm && (
              <ProductForm
                selectedProduct={state.selectedProduct}
                closeForm={() => displayProductForm(null, false)}
                saveForm={() => afterSave()}
              ></ProductForm>
            )}
          </LFade>
          <LFade transitionEnterDuration={200}>
            {state.displayConfiguration && (
              <LDialog
                className={styles.configurationDialog}
                key={props.productGuid}
                width="800"
                title="Configure Product"
                body={
                  <CPConfigurationManagement
                    productGuid={state.selectedProductGuid}
                    clientGuid={null}
                    closeForm={() => displayConfigurationForm(null, false)}
                    saveSuccessForm={() => afterConfigSave(null, false)}
                  ></CPConfigurationManagement>
                }
                actionCloseButton={() => displayConfigurationForm(null, false)}
                actionOverlay={() => displayConfigurationForm(null, false)}
              />
            )}
          </LFade>
        </div>
      ) : null}
    </div>
  );
}

export { ProductTable };
