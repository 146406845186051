import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProductCard } from "./ProductCard/ProductCard";
import { AuthContext } from "../../../context/AuthContext";
import styles from "./ProductDashboard.module.css";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function ProductDashboard(props) {
  const context = useContext(AuthContext);
  const [state, setState] = useState({
    isLoaded: false,
    isLoading: true,
    products: [],
  });

  useEffect(() => {
    if (context.isLoaded() && context.authState() && !state.isLoaded) {
      setState(s => ({ ...s, isLoading: true }));

      let fPath = GATEWAY_BASE + "/multitenancy/api/users/" + context.userInfo().loffaUserGuid + "/products";

      fetch(
        fPath,
        {
          mode: "cors",
          headers: {
            Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
          },
        }
      )
        .then((res) =>
          res.json()
        )
        .then(
          (result) => {
            // TODO If there's only one product (with one client configuration), we want to redirect to that product, else, show options.
            setState(s => ({
              ...s,
              isLoaded: true,
              isLoading: false,
              products: result,
            }));
          },
          (error) => {
            setState(s => ({
              ...s,
              isLoaded: false,
              isLoading: false,
              error,
            }));
          }
        );
    }
  }, [context, state.isLoaded]);

  const mapProducts = () => {
    let productCards = state.products.map((product) => (
      <ProductCard
        key={product.productGuid}
        productGuid={product.productGuid}
        productName={product.name}
        productSubName={product.subName}
        productDescription={product.description}
        productVersion={product.version}
        productReleaseDate={new Date(product.releaseDate).toLocaleDateString()}
        productUrl={product.url}
        productLogoFileName={product.productLogoFileName}
        productTypeId={product.productTypeId}
      />
    ));

    while (productCards.length % 4 !== 0) {
      productCards.push(<ProductCard key={Math.random()} isVisible={false} />);
    }

    return productCards;
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  return (
    <div>
      <div className="sectionTitle">
        <FontAwesomeIcon icon="fa-solid fa-grid-2" /> Products
      </div>
      <div className={styles.productCardContainer + " k-card-deck"}>
        {state.isLoading && loadingPanel}
        {state.products.length > 0 ? (
          mapProducts()
        ) : (
          <p>
            You have no products assigned to you. Please contact a supervisor
            or administrator for access.
          </p>
        )}
      </div>
    </div>
  );
}

export { ProductDashboard };
