import React, { useContext, useState, useEffect } from "react";
import { LButton, LFormInput, LDialog } from "@loffa/gpp-modules";
import { AuthContext } from "../../../../../context/AuthContext";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function ProductForm(props) {
  const context = useContext(AuthContext);
  const [state, setState] = useState({
    product: props.selectedProduct,
    isEditMode: props.selectedProduct.productGuid !== undefined,
    saveSuccessful: false,
  });

  const [permState, setPermState] = useState({
    permissionConfigurationTypes: [],
    isLoaded: false,
  });
  const [typeState, setTypeState] = useState({
    productTypes: [],
    isLoaded: false,
  });

  useEffect(() => {    
    let authContext = context;
    let url = GATEWAY_BASE + "/multitenancy/api/Products/PermissionTypes";
    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 204) {
          return [];
        } else {
          return res.json();
        }
      })
      .then(
        function (response) { 
          setPermState(s => ({
            ...s,
            permissionConfigurationTypes: response.permissionConfigurationTypes,
            isLoaded: true,          
          }));

        },
        (error) => {
          console.log(error);
        }
      );        
  }, [context]);


  useEffect(() => {
    let authContext = context;
    let url = GATEWAY_BASE + "/multitenancy/api/Products/ProductTypes";

    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        //console.log(res);
        if (res.status === 204) {
          return [];
        } else {
          return res.json();
        }
      })
      .then(
        function (response) {         
          setTypeState(s => ({
            ...s,
            productTypes: response.productTypes.result, 
            isLoaded: true,           
          }));          
        },
        (error) => {
          console.log(error);
        }
      );
  }, [context]);
  

  const handleStateChange = (e) => {
    setState({
      ...state,
      product: {
        ...state.product,
        [e.target.name]: e.target.value,
      },
    });
  };

  const toggleActiveState = () => {
    setState({
      ...state,
      product: {
        ...state.product,
        isActive: !state.product.isActive,
      },
    });
  };

  const setDialogTitle = () => {
    if (state.isEditMode) {
      return "Edit Product: " + state.product.name;
    } else {
      return "Add New Product";
    }
  };

  const handleSubmit = (e) => {
    e.currentTarget.checkValidity();
    e.preventDefault();
    let authContext = context;
    let method = state.isEditMode ? "PATCH" : "POST";
    let url = GATEWAY_BASE + "/multitenancy/api/Products";

    fetch(url, {
      method: method,
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(state.product),
    }).then(
      (result) => {
        if (result.ok) {
          props.saveForm();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };



  const getComboBoxValue = (types, id) => {
    return types.find((st) => st.id === id)
  }


const handleProductChange = (e) => {
  setState({
    ...state,
    product: {
      ...state.product,
      [e.target.name]: e.target.value.id,
    },
    isModified: true,
  });
};

  const handleStateChangeWithNameValue = (name, e) => {
    setState({
      ...state,
      product: {
        ...state.product,
        [name]: e.value,
      },
      isModified: true,
    });
  };

  const activeStatusButton = () => {
    if (state.isEditMode) {
      return state.product.isActive ? (
        <LButton
          type="button"
          onClick={() => {
            toggleActiveState();
          }}
        >
          Set as Inactive
        </LButton>
      ) : (
        <LButton
          type="button"
          onClick={() => {
            toggleActiveState();
          }}
        >
          Set as Active
        </LButton>
      );
    }
  };



  if(permState.isLoaded === false || typeState.isLoaded === false){    
    return (      
      <div>
        <form>
          <div>Loading...</div>
        </form>
      </div>
    );
  }

  return (    
    <div>
      <form onSubmit={handleSubmit}>
        <LDialog
          key={state.product.productGuid}
          width="500"
          title={setDialogTitle()}
          body={
            <div>
              <div>{activeStatusButton()}</div>
              <div className="required-field">* required</div>
              <LFormInput
                labelName="Name *"
                type="input"
                onChange={(event) => handleStateChange(event)}
                input={{
                  value: state.product.name,
                  onChange: (event) => handleStateChange(event),
                  name: "name",
                  required: true,
                }}
              />
              <LFormInput
                labelName="Sub Name"
                type="input"
                onChange={(event) => handleStateChange(event)}
                input={{
                  value: state.product.subName,
                  onChange: (event) => handleStateChange(event),
                  name: "subName",
                }}
              />
              <LFormInput
                labelName="URL *"
                type="input"
                onChange={(event) => handleStateChange(event)}
                input={{
                  value: state.product.url,
                  onChange: (event) => handleStateChange(event),
                  name: "url",
                  required: true,
                }}
              />
              <LFormInput
                labelName="Description"
                type="textarea"
                textArea={{
                  value: state.product.description ?? "",
                  onChange: (event) =>
                    handleStateChangeWithNameValue("description", event),
                  name: "description",
                  rows: 5,
                }}
              />
              <LFormInput
                labelName="Product Logo File Name"
                type="input"
                onChange={(event) => handleStateChange(event)}
                input={{
                  value: state.product.productLogoFileName
                    ? state.product.productLogoFileName
                    : "",
                  onChange: (event) => handleStateChange(event),
                  name: "productLogoFileName",
                }}
              />
              <LFormInput
                labelName="Release Date"
                type="datepicker"
                onChange={(event) => handleStateChange(event)}
                datePicker={{
                  defaultValue: state.product.releaseDate ?? "",
                  onChange: (event) => handleStateChange(event),
                  name: "releaseDate",
                }}
              />
              <LFormInput
                labelName="Version"
                type="input"
                onChange={(event) => handleStateChange(event)}
                input={{
                  value: state.product.version,
                  onChange: (event) => handleStateChange(event),
                  name: "version",
                }}
              />
              <LFormInput
                labelName="Permission Config Type"
                type="combobox"
                comboBox={{
                  name: "permissionConfigurationTypeId",
                  data: permState.permissionConfigurationTypes,
                  textField: "name",
                  dataItemKey: "id",
                  value: getComboBoxValue(
                    permState.permissionConfigurationTypes,
                    state.product.permissionConfigurationTypeId
                  ),
                  onChange: (event) => handleProductChange(event),                  
                }}
              />

              <LFormInput
                labelName="Product Type*"
                type="combobox"
                comboBox={{
                  name: "productTypeId",
                  data: typeState.productTypes,
                  textField: "name",
                  dataItemKey: "id",
                  value: getComboBoxValue(
                    typeState.productTypes,
                    state.product.productTypeId
                  ),
                  onChange: (event) => handleProductChange(event),
                }}
              />

              <LFormInput
                labelName="Admin Only? *"
                type="checkbox"
                checkbox={{
                  value: state.product.isAdminOnly,
                  onChange: (event) =>
                    handleStateChangeWithNameValue("isAdminOnly", event),
                }}
              />
              <LFormInput
                labelName="Is MultiTenant? *"
                type="checkbox"
                checkbox={{
                  value: state.product.isMultiTenant,
                  onChange: (event) =>
                    handleStateChangeWithNameValue("isMultiTenant", event),
                }}
              />
            </div>
          }
          actionLayout="end"
          action={
            <React.Fragment>
              <LButton onClick={props.closeForm}>Cancel</LButton>
              <LButton themeColor="primary" className="green" type="submit">
                Save
              </LButton>
            </React.Fragment>
          }
          actionCloseButton={props.closeForm}
          actionOverlay={props.closeForm}
        />
      </form>
    </div>
  );
}

export { ProductForm };
