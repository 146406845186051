import React from "react";
import { UserTable } from "./UserTable/UserTable";

function UserManagement() {
  return (
    <div>
      <UserTable></UserTable>
    </div>
  );
}

export { UserManagement };
