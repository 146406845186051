import React, { useContext, useState, useEffect, useCallback } from "react";
import { LButton, LFormInput } from "@loffa/gpp-modules";
import { AuthContext } from "../../../../../../../context/AuthContext";
import styles from './CPConfigManagement.module.css';

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function CPConfigurationManagement(props) {  
  const context = useContext(AuthContext);
  const [state, setState] = useState({
    clientGuid: props.clientGuid,
    productGuid: props.productGuid,  
    productTypeId: {},
    clientProduct: {},
    storageTypes: [],
    isModified: false,
    isLoading: false
  });
  const [dtcState, setDTCState] = useState({
    DTCs: []
  })


  const getBaseProductUrl = useCallback(async () => {
    let productUrl =
      GATEWAY_BASE + "/multitenancy/api/products/" + props.productGuid;
  
    try {
      const res = await fetch(productUrl, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
          "Content-Type": "application/json",
        },
      });
      const response = await res.json();
  
      // Note: hijacking this call to retrieve the product type id
      const productTypeId = response.productTypeId;
  
      // Now, set the state and wait for the update
      setState(prevState => ({
        ...prevState,        
        productTypeId
      }));
  
      return { baseUrl: response.url, productTypeId };
    } catch (error) {
      console.log(error);
    }
  }, [context, props.productGuid]);
  
  const syncAssociatedDTC = async () => {
    try {
      // Get and set associated DTC
      const { baseUrl, productTypeId } = await getBaseProductUrl();
     
      const lowerCaseProductTypeId = productTypeId.toLowerCase();
      const id1 = "9DF3F6BA-4F6F-44DA-AF2E-6C05356B0545".toLowerCase(); //FVD-R
      const id2 = "B2649D41-F6F0-4075-B912-06C7A6A62B6F".toLowerCase(); //FVD-S
  
      // Compare the productTypeId and set the appropriate URL
      let productUrl;
      if (lowerCaseProductTypeId === id1 || lowerCaseProductTypeId === id2) {
        productUrl = `${baseUrl}/api/Multitenancy/GetAssociatedDTCs/${state.clientGuid}/${productTypeId}`;
      } else {
        productUrl = `${baseUrl}/api/multitenancy/GetAllAssociatedDTCs`;
      }
  
      const response = await fetch(productUrl, {
        mode: "cors",
        method: "GET",
        headers: {
          Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
          AccessToken: `${context.authState().accessToken.accessToken}`,
          ClientGuid: props.clientGuid,
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const dtcList = await response.json();
      const json = JSON.stringify(dtcList.DTCs);
      const uri = `${GATEWAY_BASE}/multitenancy/api/Integration/${props.clientGuid}/${props.productGuid}/UpdateDTCs`;
  
      await fetch(uri, {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
          'Content-Type': 'application/json'
        },
        method: "PUT",
        body: json
      });
  
      setDTCState(s => ({
        ...s,
        DTCs: dtcList.DTCs,
      }));
    } catch (error) {
      console.error("Error fetching DTCs:", error);
      setDTCState(s => ({
        ...s,
        DTCs: ["Error syncing Associated DTC#s. Is the application and database configuration available for use? See console log."],
      }));
    }
  };
  

  const bindData = useCallback(async () => {
    setState(s => ({ ...s, isLoading: true }))

    if (props.clientGuid !== null) {
      await syncAssociatedDTC();
    }

    let url =
      GATEWAY_BASE +
      "/multitenancy/api/Clients/" +
      state.clientGuid +
      "/Products/" +
      state.productGuid +
      "/Configuration";

    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
      },
    })
      .then((res) => {
        if (res.status === 204) {
          return [];
        } else {
          return res.json();
        }
      })
      .then(function (response) {
        setState(s => ({
          ...s,
          clientProduct: response.clientProduct,
          initialClientProduct: response.clientProduct,
          storageTypes: response.storageTypes,
          isLoading: false
        }));
      })
      .catch((error) => {
        console.log(error);
        setState(s => ({
          ...s,
          isLoading: false
        }));
      });
  }, [context, state.clientGuid, state.productGuid]);

  useEffect(() => {
    bindData();
  }, [bindData]);

  const resetForm = () => {
    setState({ ...state, clientProduct: state.initialClientProduct });
  };

  const getComboBoxValue = (types, id) => {
    return types.find((st) => st.id === id);
  };

  const handleStateChange = (e) => {
    setState({
      ...state,
      clientProduct: {
        ...state.clientProduct,
        [e.target.name]: e.target.value,
        lastUpdatedBy: context.userInfo().loffaUserGuid,
      },
      isModified: true,
    });
  };

  const handleComboBoxChange = (e) => {
    setState({
      ...state,
      clientProduct: {
        ...state.clientProduct,
        [e.target.name]: e.target.value.id,
      },
      isModified: true,
    });
  };

  const handleSubmit = (e) => {
    e.currentTarget.checkValidity();
    e.preventDefault();
    let authContext = context;
    let method = "PUT";
    let url =
      GATEWAY_BASE +
      "/multitenancy/api/Clients/" +
      state.clientGuid +
      "/Products/" +
      state.productGuid +
      "/Configuration";

    fetch(url, {
      method: method,
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(state.clientProduct),
    }).then(
      (res) => {
        if (res.status === 200) {
          setState({
            ...state,
            isModified: false,
          });
        }

        if (props.saveSuccessForm) {
          props.saveSuccessForm();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  return (
    <div >
      {state.isLoading && loadingPanel}
      <form onSubmit={handleSubmit}>
        {props.clientGuid !== null && (
          <div>
            <div className={styles.configHeader}>E2E (PBIN Network)</div>
            <LFormInput
              labelName="E2E Enabled"
              type="switch"
              onChange={(event) => handleStateChange(event)}
              switch={{
                value: state.clientProduct.e2EIsEnabled,
                onChange: (event) => handleStateChange(event),
                name: "e2EIsEnabled",
                onLabel: "Enabled",
                offLabel: "Disabled",
                checked: state.clientProduct.e2EIsEnabled,
                width: 90
              }}
            />
          </div>
        )}
        <div>
          <div className={styles.configHeader}>Database</div>
          <LFormInput
            labelName="Database Server"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.databaseServer,
              onChange: (event) => handleStateChange(event),
              name: "databaseServer",
            }}
          />
          <LFormInput
            labelName="Database Catalog"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.databaseCatalog,
              onChange: (event) => handleStateChange(event),
              name: "databaseCatalog",
            }}
          />
          <LFormInput
            labelName="Database User"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.databaseUser,
              onChange: (event) => handleStateChange(event),
              name: "databaseUser",
            }}
          />
          <LFormInput
            labelName="Database Password"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.databasePassword,
              onChange: (event) => handleStateChange(event),
              name: "databasePassword",
            }}
          />

          <div className={styles.configHeader}>Auth Database</div>
          <LFormInput
            labelName="Auth Database Server"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.authDatabaseServer,
              onChange: (event) => handleStateChange(event),
              name: "authDatabaseServer",
            }}
          />
          <LFormInput
            labelName="Auth Database Catalog"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.authDatabaseCatalog,
              onChange: (event) => handleStateChange(event),
              name: "authDatabaseCatalog",
            }}
          />
          <LFormInput
            labelName="Auth Database User"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.authDatabaseUser,
              onChange: (event) => handleStateChange(event),
              name: "authDatabaseUser",
            }}
          />
          <LFormInput
            labelName="Auth Database Password"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.authDatabasePassword,
              onChange: (event) => handleStateChange(event),
              name: "authDatabasePassword",
            }}
          />

          <div className={styles.configHeader}>Storage</div>
          <LFormInput
            labelName="Storage Type"
            type="combobox"
            comboBox={{
              name: "storageTypeId",
              data: state.storageTypes,
              textField: "name",
              dataItemKey: "id",
              value: getComboBoxValue(
                state.storageTypes,
                state.clientProduct.storageTypeId
              ),
              onChange: (event) => handleComboBoxChange(event),
            }}
          />
          <LFormInput
            labelName="Storage Server User Name"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.storageServerUserName,
              onChange: (event) => handleStateChange(event),
              name: "storageServerUserName",
            }}
          />
          <LFormInput
            labelName="Storage Server User Password"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.storageServerUserPassword,
              onChange: (event) => handleStateChange(event),
              name: "storageServerUserPassword",
            }}
          />
          <LFormInput
            labelName="Storage Path"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.storagePath,
              onChange: (event) => handleStateChange(event),
              name: "storagePath",
            }}
          />

          <div className={styles.configHeader}>Incoming Handler</div>
          <LFormInput
            labelName="Email Server"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.incomingHandlerEmailServer,
              onChange: (event) => handleStateChange(event),
              name: "incomingHandlerEmailServer",
            }}
          />
          <LFormInput
            labelName="Email Username"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.incomingHandlerEmailUser,
              onChange: (event) => handleStateChange(event),
              name: "incomingHandlerEmailUser",
            }}
          />
          <LFormInput
            labelName="Email Password"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.incomingHandlerEmailPassword,
              onChange: (event) => handleStateChange(event),
              name: "incomingHandlerEmailPassword",
            }}
          />
          <LFormInput
            labelName="Email Port"
            type="input"
            onChange={(event) => handleStateChange(event)}
            input={{
              value: state.clientProduct.incomingHandlerConnectionPort,
              onChange: (event) => handleStateChange(event),
              name: "incomingHandlerConnectionPort",
            }}
          />
          {props.clientGuid !== null && (
            <div>
            <div className={styles.configHeader}>Associated DTCs</div>
            <ol>
              {
                dtcState.DTCs.map(item => {
                  return <li key={item}>{item}</li>;
                }
                )
              }
            </ol>
            </div>)}


        </div>
        {state.isModified && (
          <div>
            <LButton onClick={resetForm} type="button">
              Discard Changes
            </LButton>
            <span> </span>
            <LButton themeColor="primary" className="green" type="submit">
              Save
            </LButton>
          </div>
        )}
      </form>
    </div>
  );
}

export { CPConfigurationManagement };
