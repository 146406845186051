import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import { LButton, LFormInput, LDialog } from "@loffa/gpp-modules";
import { AuthContext } from "../../../../../../../../context/AuthContext";
import { CPUPP_PBINConfig } from "./CPUPermissions/CPUPP_PBINConfig";
import { CPUPP_PBINGABConfig } from "./CPUPermissions/CPUPP_PBINGABConfig";
import styles from "./CPUManagementForm.module.css";
import _ from "lodash";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function CPUserManagementForm({ saveForm, ...props }) {
  const context = useContext(AuthContext);

  const [currentStep, setCurrentStep] = useState(props.selectedUser ? 2 : 1);
  const [dialogTitle] = useState("Add New User");
  const [dialogWidth] = useState(props.selectedUser ? 1000 : 500);
  const [user, setUser] = useState(props.selectedUser ?? { /* default user object */ });
  const [isLoading, setIsLoading] = useState(false);
  const userRef = useRef(user);

  // Cut my life into pieces. This is my last resort.
  // User not updating properly via State, using Ref instead
  useEffect(() => {
    userRef.current = user;
  }, [user]);

  const handleFirstNameChange = (event) => {
    setUser({ ...user, firstName: event.target.value });
  };

  const handleLastNameChange = (event) => {
    setUser({ ...user, lastName: event.target.value });
  };

  const handleEmailChange = (event) => {
    setUser({ ...user, email: event.target.value });
  };

  useEffect(() => {
    if (user.firstName && user.lastName && user.email && currentStep === 1) {
      setCurrentStep(2);
    }
  }, [user.firstName, user.lastName, user.email, currentStep]);

  const getBaseProductUrl = useCallback(async () => {
    let productUrl =
      GATEWAY_BASE + "/multitenancy/api/products/" + props.productGuid;

    try {
      const res = await fetch(productUrl, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
          "Content-Type": "application/json",
        },
      });
      const response = await res.json();
      return response.url;
    } catch (error) {
      console.log(error);
    }
  }, [context, props.productGuid]);

  const addUserToClientProduct = useCallback(async () => {
    try {
      const url = `${GATEWAY_BASE}/multitenancy/api/Clients/${props.clientGuid}/Products/${props.productGuid}/Users/`;

      const userState = {
        ...user,
        createdBy: context.userInfo().loffaUserGuid,
      };

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
          "Content-Type": "application/json",
          ClientGuid: props.clientGuid,
        },
        body: JSON.stringify(userState),
      });

      return response;
    } catch (error) {
      console.error("Error in addUserToClientProduct:", error);
      // You may want to handle or rethrow the error depending on your use case
      throw error;
    }
  }, [context, props.clientGuid, props.productGuid, user]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    let currentUser = userRef.current;

    try {
      if (!currentUser.firstName || !currentUser.lastName || !currentUser.email) {
        throw new Error("Validation failed: First name, last name, and email are required.");
      }

      setCurrentStep(2); // Update step only if validation passes

      const baseUrl = await getBaseProductUrl();
      const addOrUpdateEndpoint = currentUser.oktaId ? "/updateUser" : "/addUser";
      const method = currentUser.oktaId ? "PATCH" : "POST";
      const url = `${baseUrl}/api/multitenancy${addOrUpdateEndpoint}`;

      const addUserResponse = await addUserToClientProduct();
      if (!addUserResponse.ok) {
        throw new Error(`Error in addUserToClientProduct: ${addUserResponse.status}`);
      }
      const addUserJson = await addUserResponse.json();

      const updatedUser = {
        ...currentUser,
        lastUpdatedBy: context.userInfo().loffaUserGuid,
        oktaId: addUserJson.oktaId,
        isActiveInProduct: true,
      };

      const response = await fetch(url, {
        method: method,
        mode: "cors",
        headers: {
          Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
          "Content-Type": "application/json",
          ClientGuid: props.clientGuid,
        },
        body: JSON.stringify(updatedUser),
      });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      else {
        saveForm();
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    } finally {
      setIsLoading(false);
    }
  }, [userRef, addUserToClientProduct, getBaseProductUrl, context, props.clientGuid, saveForm]);


  const setLoading = (e) => {
    setIsLoading(e);
  }

  const updatePermissions = useCallback((newPermissions) => {
    if (!_.isEqual(user.permissions, newPermissions)) {
      setUser(currentUserData => ({
        ...currentUserData,
        permissions: newPermissions
      }));
    }
  }, [user]);
  
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const renderUserForm = () => {
    return (
      <div>
        <LFormInput
          labelName="First Name *"
          type="input"
          onChange={(event) => handleFirstNameChange(event)}
          input={{
            value: user.firstName,
            onChange: (event) => handleFirstNameChange(event),
            name: "firstName",
            required: true,
            valid: user.firstName,
          }}
        />
        <LFormInput
          labelName="Last Name *"
          type="input"
          onChange={(event) => handleLastNameChange(event)}
          input={{
            value: user.lastName,
            onChange: (event) => handleLastNameChange(event),
            name: "lastName",
            required: true,
            valid: user.lastName,
          }}
        />
        <LFormInput
          labelName="Email *"
          type="input"
          onChange={(event) => handleEmailChange(event)}
          input={{
            value: user.email,
            onChange: (event) => handleEmailChange(event),
            name: "email",
            required: true,
            valid: user.email,
          }}
        />
      </div>
    );
  };

  const renderPermissionForm = () => {
    return (
      <div>
        <h3>Select Roles and Permissions</h3>
        {props.configType === 1 && (
          // eslint-disable-next-line react/jsx-pascal-case
          <CPUPP_PBINConfig
            clientGuid={props.clientGuid}
            productGuid={props.productGuid}
            user={user}
            updatePermissions={updatePermissions}
            setParentLoading={setLoading}
            saveForm={saveForm}
            closeForm={props.closeForm}
          ></CPUPP_PBINConfig>
        )}
        {props.configType === 4 && (
          // eslint-disable-next-line react/jsx-pascal-case
          <CPUPP_PBINGABConfig />
        )}
      </div>
    );
  };

  // Main render
  return (
    <div className="relative-div">
      <LDialog
        className={styles.permissionModal}
        width={dialogWidth}
        title={dialogTitle}
        body={
          <div>
            {isLoading && loadingPanel}
            <div className="required-field">* required</div>
            {currentStep === 1 && renderUserForm()}
            {currentStep === 2 && renderPermissionForm()}
          </div>
        }
        actionLayout="end"
        action={
          <React.Fragment>
            <LButton onClick={props.closeForm}>Cancel</LButton>
            {currentStep === 1 && (
              <LButton onClick={() => setCurrentStep(2)}>Next</LButton>
            )}
            {currentStep === 2 && (
              <LButton
                themeColor="primary"
                className="green"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Save
              </LButton>
            )}
          </React.Fragment>
        }
        actionCloseButton={props.closeForm}
        actionOverlay={props.closeForm}
      >
      </LDialog>
    </div>
  );
}

export { CPUserManagementForm };
