// src/OktaSignInWidget.js

import React, { useEffect } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "./OktaSignInWidget.css";
import "../../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { useNavigate } from "react-router-dom";

const OktaWidget = () => {
  useNavigate();

  useEffect(() => {
    const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
    const OKTA_AUTHZ = process.env.REACT_APP_OKTA_AUTHZ_PATH;
    const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_SSO_CLIENT_ID;
    const REDIRECT_URI = window.location.origin;

    const widget = new OktaSignIn({
      baseUrl: 'https://' + OKTA_DOMAIN + '/',
      language: 'en',
      i18n: {
        'en': {
          'primaryauth.title': 'Log In',
          'primaryauth.submit': 'Log In',
          'errors.E0000004': 'Invalid Credentials'
        }
      },
      clientId: OKTA_CLIENT_ID,
      redirectUri: REDIRECT_URI + '/implicit/callback',
      useClassicEngine: true,
      authParams: {
        scopes: ['openid', 'email', 'profile', 'roles'],
        issuer: 'https://' + OKTA_DOMAIN + OKTA_AUTHZ,
      },
      features: {
        router: true,
        rememberMe: false,
        idpDiscovery: true // Enable for SSO using IDP like Ping Identity
      },
      idpDiscovery: {
        requestContext: window.location.origin + "/samlredirect"
      },
      preSubmit: (postData, onSuccess) => {
        console.log(postData);
      },
      helpLinks: null,
      el: '#okta-sign-in-container'
    });

    widget.showSignInAndRedirect({ el: '#okta-sign-in-container' }).catch(function (error) {
      // This function is invoked with errors the widget cannot recover from:
      // Known errors: CONFIG_ERROR, UNSUPPORTED_BROWSER_ERROR
    });

    widget.on('ready', function (context) {
      let oktaSignInPassword = document.getElementById('okta-signin-password');

      if (oktaSignInPassword !== null) {
        oktaSignInPassword.addEventListener("keyup", (event) => {
          oktaSignInPassword.value = oktaSignInPassword.value.trim();
        });
      }
    });
    
    return () => {
      widget.remove();
    }

  }, []);

  return (
    <div id="okta-sign-in-container" />
  );
};

export default OktaWidget;