import React, { useContext } from "react";
import { LGrid, LSwitch } from "@loffa/gpp-modules";
import KeyBasedLButton from "../../../../../Shared/KeyBasedLButton";
import { AuthContext } from "../../../../../../context/AuthContext";
const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function ClientProducts(props) {
  const context = useContext(AuthContext);
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const hasData = () => {
    if (props.data) {
      return props.data.length > 0;
    }
    else {
      return false;
    }
  };

  const toggleClientProduct = (e, productGuid) => {
    let authContext = context;

    fetch(
      GATEWAY_BASE +
      "/multitenancy/api/Clients/" +
      props.selectedClient.clientGuid +
      "/Products/" +
      productGuid,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ setEnabled: e.value }),
      }
    )
      .then(function (response) {
        if (response.ok) {
          props.rebind(props.selectedClient);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sortPropData = (data) => {
    let flattenedData = data.map((data) => {
      return {
        ...data.product,
        isEnabledForClient: data.isEnabledForClient,
      };
    });

    return [
      ...flattenedData.filter((c) => c.isEnabledForClient === true),
      ...flattenedData.filter((c) => c.isEnabledForClient === false),
      ...flattenedData.filter((c) => c.isEnabledForClient === null),
    ];
  };

  let toggleCell = (value) => {
    return (
      <td>
        <div>
          {value.dataItem.isEnabledForClient !== null && (
            <LSwitch
              width={100}
              id={
                value.dataItem.productGuid +
                props.selectedClient.clientGuid
              }
              onChange={(e) =>
                toggleClientProduct(e, value.dataItem.productGuid)
              }
              onLabel={"Enabled"}
              offLabel={"Disabled"}
              defaultChecked={value.dataItem.isEnabledForClient}
            ></LSwitch>
          )}
        </div>
      </td>
    );
  };

  let addManageCell = (value) => {
    return (
      <td>
        <KeyBasedLButton
          onClick={
            value.dataItem.isEnabledForClient === null
              ? () =>
                toggleClientProduct(
                  { value: true },
                  value.dataItem.productGuid
                )
              : () =>
                props.onManageClientProductClick(
                  value.dataItem
                )
          }
          parentKey={value.dataItem.productGuid}
          buttonText={value.dataItem.isEnabledForClient === null ? "Add" : "Manage"}
        ></KeyBasedLButton>
      </td>
    );
  };

  return (
    <div>
      <div>
        {hasData() ? (
          <div>
            <div>
              {props.data === null && loadingPanel}
              <LGrid
                sortable
                filterable
                pageable
                data={sortPropData(props.data)}
                style={{ height: "400px" }}
                columns={[
                  {
                    field: "name",
                    title: "Product",
                  },
                  {
                    field: "subName",
                    title: "Sub Name",
                  },
                  {
                    field: "isEnabledForClient",
                    title: " ",
                    width: "125px",
                    filterable: false,
                    cell: toggleCell,
                  },
                  {
                    field: "isEnabledForClient",
                    title: " ",
                    width: "125px",
                    filterable: false,
                    cell: addManageCell,
                  },
                ]}
              ></LGrid>
            </div>
          </div>
        ) : (
          <p>No products found.</p>
        )}
      </div>
    </div>
  );
}

export { ClientProducts };
