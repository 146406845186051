import React, { useContext, useState, useEffect, useCallback } from "react";
import { LCard, LDialog, LComboBox, LFade } from "@loffa/gpp-modules";
import { AuthContext } from "../../../../context/AuthContext";
import styles from "./ProductCard.module.css";
import KeyBasedLButton from "../../../Shared/KeyBasedLButton";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_PRODUCT_CLIENT_ID;
const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const OKTA_AUTHZ_PATH = process.env.REACT_APP_OKTA_AUTHZ_PATH;

function ProductCard(props) {
  const context = useContext(AuthContext);
  const [state, setState] = useState({
    displayClientSelection: false,
    clients: [],
    selectedClient: null,
    versionNumber: null,
    versionDate: null
  });

  const goToProduct = useCallback(() => {
    let url =
      "https://" +
      OKTA_DOMAIN +
      OKTA_AUTHZ_PATH +
      "/v1/authorize" +
      "?client_id=" +
      OKTA_CLIENT_ID +
      "&response_type=code" +
      "&scope=roles+offline_access" +
      "&redirect_uri=" +
      props.productUrl +
      "/verifyauth.aspx" +
      "&state=" +
      btoa(props.productGuid + "|" + state.selectedClient + "|" + props.productTypeId);
    window.location.replace(url);
  }, [props, state.selectedClient]);

  const getVersion = useCallback((accessToken) => {
    let productVersionUrl =
      props.productUrl +
      "/api/Multitenancy/GetProductVersion";

    fetch(productVersionUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (result) => {
          setState(s =>
          ({
            ...s,
            versionNumber: result.Version,
            versionDate: result.Date
          })
          );
        },
        (error) => {
          setState(s => ({
            ...s,
            versionNumber: 'N/A',
            versionDate: 'N/A',
            error,
          }));
        }
      );
  }, [props]);

  useEffect(() => {
    if (context.authState() != null && typeof (context.authState()) != "undefined" && state.versionNumber == null && props.productGuid != null) {
      getVersion(context.authState().accessToken.accessToken);
    }
  }, [context, getVersion, state.versionNumber, props.productGuid]);

  useEffect(() => {
    if (!state.displayClientSelection && state.selectedClient !== null) {
      goToProduct();
    }
  }, [goToProduct, state.displayClientSelection, state.selectedClient]);

  const checkClients = () => {
    let authContext = context;

    fetch(
      GATEWAY_BASE +
      "/multitenancy/api/users/" +
      authContext.userInfo().loffaUserGuid +
      "/" +
      props.productGuid +
      "/clients",
      {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          // TODO If multiple, allow selection, else go to product
          if (result.length > 1) {
            setState({
              ...state,
              displayClientSelection: true,
              clients: result,
              selectedClient: null,
            });
          } else if (result.length === 1) {
            setState({
              ...state,
              displayClientSelection: false,
              selectedClient: result[0].clientGuid,
            });
          }
        },
        (error) => {
          setState({
            ...state,
            isLoaded: false,
            error,
          });
        }
      );
  };

  const toggleClientSelection = () => {
    setState({
      ...state,
      displayClientSelection: false,
      clients: [],
      selectedClient: null,
    });
  };

  const selectClient = (e) => {
    setState(s => ({
      ...s,
      selectedClient: e.value.clientGuid,
    }));
  };

  let body = (
    <div>
      <div>{props.productDescription}</div>
    </div>
  );

  let header = (
    <div>
      <div className={styles.productIcon}>
        <img className={styles.imgIcon} alt={props.productName + " Product Logo"}
          src={`/assets/productIcons/${props.productLogoFileName
            ? props.productLogoFileName
            : "icon-default.png"
            }`}
        />
      </div>
      <div className={styles.productHeader}>
        <div className={styles.productHeaderName}>
          {props.productName}
        </div>
        <div className={styles.productHeaderSubName}>
          {props.productSubName}
        </div>
        <div className={styles.productHeaderVersion}>
          Version: {state.versionNumber} released on {" "}
          {state.versionDate}
        </div>
      </div>
    </div>
  );

  let selectClientHeader = (
    <div className={styles.flexContainer}>
      <img
        className={`${styles.imgIcon} ${styles.flexContainer}`}
        alt={`${props.productName} Product Logo`}
        src={`/assets/productIcons/${props.productLogoFileName ? props.productLogoFileName : "icon-default.png"}`}
      />
      <div>
        <strong>{props.productName}</strong><br />
        <span className={styles.smallText}>Please select a client</span>
      </div>
    </div>
  );

  return (
    <div
      className={styles.productCard}
      style={{
        visibility: props.isVisible === false ? "hidden" : "visible",
      }}
    >
      <LCard
        style={{ width: "425px" }}
        customHeader={header}
        body={body}
        clickAction={checkClients}
      ></LCard>
      <LFade transitionEnterDuration={200}>
        {state.displayClientSelection && (
          <div>
            <LDialog
              key={props.productGuid}
              width="400"
              title={selectClientHeader}
              body={
                <div>
                  <LComboBox
                    style={{ width: "100%" }}
                    data={state.clients}
                    textField="formattedName"
                    dataItemKey="clientGuid"
                    placeholder="Select Client"
                    onChange={selectClient}
                  ></LComboBox>
                </div>
              }
              actionLayout="stretched"
              action={
                state.selectedClient ? (
                  <KeyBasedLButton
                    themeColor="primary"
                    className={`green ${styles.stretchButton}`}
                    onClick={goToProduct}
                    parentKey={state.selectedClient}
                    buttonText={"Go"}
                  >
                  </KeyBasedLButton>
                ) : null
              }
              actionCloseButton={toggleClientSelection}
              actionOverlay={toggleClientSelection}
            />
          </div>
        )}
      </LFade>
    </div>
  );
}

export { ProductCard };
