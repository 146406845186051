import { LDialog, LGrid } from "@loffa/gpp-modules";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { AuthContext } from "../../../../../../../../context/AuthContext";
import KeyBasedLButton from "../../../../../../../Shared/KeyBasedLButton"
import styles from "./CPUManagementAssign.module.css";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function CPUserManagementAssign(props) {
    const context = useContext(AuthContext);
    const [state, setState] = useState({
        selectedUser: null,
        nonAssignedUsers: []
    });
    const [isLoading, setIsLoading] = useState(false);

    const bindData = useCallback(() => {
        setIsLoading(true);

        let url =
            GATEWAY_BASE +
            "/multitenancy/api/Clients/" +
            props.selectedClient.clientGuid +
            "/Products/" +
            props.selectedProduct.productGuid +
            "/Users?isAssigned=false";

        fetch(url, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
            },
        })
            .then((res) => {
                setIsLoading(false);

                if (res.status === 204) {
                    return [];
                } else {
                    return res.json();
                }
            })
            .then(function (response) {
                setState(s => ({ ...s, nonAssignedUsers: response }));
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, [context, props.selectedClient.clientGuid, props.selectedProduct.productGuid ]);

    useEffect(() => {
        bindData();
    }, [bindData]);

    const assignUser = (userGuid) => {
        let selectedUser = state.nonAssignedUsers.find(
            (obj) => obj.userGuid === userGuid
        );

        props.addEditUser(selectedUser)
    };

    const assignCell = (value) => {
        return (<td><KeyBasedLButton 
            parentKey={value.dataItem.userGuid}         
            onClick={() => assignUser(value.dataItem.userGuid)}
            buttonText="Assign"></KeyBasedLButton></td>);
    }

    const nameCell = (value) => {
        return (
            <td>
                <p>
                    <strong>{value.dataItem.getFullName}</strong>
                </p>
                <p>{value.dataItem.email}</p>
            </td>
        );
    };

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    return (
        <LDialog
            width="700"
            title="Assign Users"
            body={
                <div>
                    {isLoading && loadingPanel}
                    <div>
                        <div className={styles.infoPanel}>
                            If you do not see a user here, please verify they have been added in the User section of the administration section.
                        </div>
                        {state.nonAssignedUsers.length > 0 &&
                            <LGrid
                                sortable
                                filterable
                                pageable
                                data={state.nonAssignedUsers}
                                style={{ height: "400px" }}
                                columns={[
                                    {
                                        field: "getFullName",
                                        title: "Name",
                                        cell: nameCell,
                                    },
                                    {
                                        field: "userGuid",
                                        title: " ",
                                        width: "125px",
                                        filterable: false,
                                        sortable: false,
                                        cell: assignCell,
                                    },
                                ]}
                            ></LGrid>
                        }
                    </div>
                </div>
            }
            actionCloseButton={props.closeForm}
            actionOverlay={props.closeForm}
        />
    );
}

export { CPUserManagementAssign };
