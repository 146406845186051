import React from "react";
import styles from "./Dashboard.module.css";
import { ProductDashboard } from "./Products/ProductDashboard";
import AccountSettings from "./AccountSettings/AccountSettings";
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { ManagementDashboard } from "./Management/ManagementDashboard";

export default function Dashboard() {
  return (
    <div className={styles.loffaDashboard}>
      <Routes>
        <Route
          path="administration/*"
          element={<ManagementDashboard />}
        />
        <Route
          path="products/*"
          element={<ProductDashboard />}
        />
        <Route
          path="accountsettings/*"
          element={<AccountSettings />}
        />
        <Route element={<Navigate to="products" />} />
      </Routes>
    </div>
  );
};
