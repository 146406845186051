import React, { useState } from 'react';

const NotificationContext = React.createContext();

export function NotificationProvider(props) {
    const [notifications, setNotifications] = useState([]);
    
    let addNotification = (message, type) => {
        let newNotification = { key: notifications.length + 1, message: message, type: type }

        setNotifications([...notifications, newNotification]);

        if (type === 'success'){
            let timeout = setTimeout(() => {
                removeNotification(newNotification.key);
            }, 3000)
    
            return () => {
                clearTimeout(timeout);
            }
        }
    }

    let addError = (message) => {
        addNotification(message, 'error');
    }

    let addSuccess = (message) => {
        addNotification(message, 'success');
    }

    let addInfo = (message) => {
        addNotification(message, 'info');
    }

    let addWarning = (message) => {
        addNotification(message, 'warning');
    }

    let removeNotification = (key) => {
        setNotifications(notifications.filter(notification => notification.key !== key));
    }

    let getNotifications = () => {
        return notifications;
    }

    return (
        <NotificationContext.Provider
            value={{
                getNotifications: getNotifications,
                addNotification: addNotification,
                removeNotification: removeNotification,
                addError: addError,
                addSuccess: addSuccess,
                addInfo: addInfo,
                addWarning: addWarning
            }}>
            {props.children}
        </NotificationContext.Provider>
    );
}

export default NotificationContext;