import React, { memo } from 'react';
import { LButton } from "@loffa/gpp-modules";

function KeyBasedLButton({ parentKey, buttonText, icon, ...props }) {
    return (
        <LButton
            key={parentKey}
            {...props}
        >
            {icon} {buttonText}
        </LButton>
    );
}

function propsAreEqual(prevProps, nextProps) {
    return prevProps.parentKey === nextProps.parentKey && prevProps.buttonText === nextProps.buttonText;
}

export default memo(KeyBasedLButton, propsAreEqual);