import { useIdleTimer } from "react-idle-timer"
/**
 * @param idleTime - number of seconds to wait before user is logged out
 * @parem openModal - will open modal when (true)
 */

const useIdleTimeout = ({ onIdle, onAction, idleTime = 1 }) => {
    const idleTimeout = 1000 * idleTime;
    const onActive = (event) => {} 

    const {isIdle} = useIdleTimer({ 
        onIdle,
        onActive,        
        onAction,
        timeout: idleTimeout,
        events: [
          'mousemove',
          'keydown',
          'wheel',
          'DOMMouseScroll',
          'mousewheel',
          'mousedown',
          'touchstart',
          'touchmove',
          'MSPointerDown',
          'MSPointerMove',
          'visibilitychange',
          'focus'
        ],       
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: true,
        name: 'idle-timer'
      })

    return {
        isIdle
    }
}
export default useIdleTimeout;