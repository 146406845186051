import React, { useState, useEffect, useContext, useCallback } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Dashboard from "../Dashboard/Dashboard";
import { Header } from "../Header/Header";
import { AuthContext } from "../../context/AuthContext";
import FooterNotification from "../Notifications/FooterNotification/FooterNotification";
import useIdleTimeout from "./useIdleTimeout";

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

export default function AuthenticatedApp() {
  const authLoffaContext = useContext(AuthContext);

  const [state, setState] = useState({
    userName: null,
    remainingTimeString: ""
  });

  const [lastAuthUpdate, setLastAuthUpdate] = useState(new Date());
  const { oktaAuth, authState } = useOktaAuth();

  const onIdle = () => {
    logout()
  }

  const onAction = (event) => {
    //fires on every action tick, including mouseover.  
    //console.log("Action fired");  
    let lastAuthCheckExpired = addMinutes(new Date(), -5);
    if (lastAuthUpdate <= lastAuthCheckExpired) {
      //console.log("Re-newing authentication");
      setLastAuthUpdate(new Date());
      oktaAuth.tokenManager.renewToken(authState.accessToken); //Note: this does not appear to be working. Will need to analyze this, but not in this ticket. 
    }
  }

  useIdleTimeout({ onIdle, onAction, idleTime: 60 * 20 })

  
  let checkUser = useCallback(async() => {
    // Sign out user if they are not authenticated
    if (!authState.isAuthenticated) {
      authLoffaContext.setUserInfo(null);
      oktaAuth.signOut("/");
    }

    // If they are authenticated (signed in) and the authState (loffa context)
    if (authState.isAuthenticated && !authLoffaContext.isLoaded()) {
      let url = GATEWAY_BASE + "/multitenancy/api/Users/ByOktaId/";
      oktaAuth.getUser()
        .then((profile) => {
          fetch(url, {
            mode: "cors",
            headers: {
              Authorization: `Bearer ${authState.accessToken.accessToken}`,
            },
          })
            .then((res) => res.json())
            .then(
              (result) => {
                profile.loffaUserGuid = result.userGuid;
                profile.role = result.getUserRoleName;
                authLoffaContext.setAuthContextState({
                  ...authLoffaContext.getAuthContextState,
                  authState: authState,
                  userInfo: profile,
                  isLoaded: true
                });

                return profile.name;
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((ex) => {
          oktaAuth.signOut("/");
        });
    }
  }, [authLoffaContext, authState, oktaAuth]) //checkuser

  // end new stuff
  useEffect(() => {
    let isMounted = true;

    checkUser().then(name => {
      if (isMounted) setState({ userName: name });
    });

    return () => { isMounted = false };
  }, [checkUser]);


  let logout = async () => {
    //console.log("AuthenticatedApp.js:84 logout fired");
    oktaAuth.signOut("/");
  };

  return (
    <div id="main">
      <Header onLogoutClick={logout} userName={state.userName}></Header>
      <Dashboard></Dashboard>
      <FooterNotification></FooterNotification>
    </div>
  ); //return
} //default AuthenticatedApp export
