import React from "react";
import styles from "./AccountSettings.module.css";
import { ChangePassword } from "./ChangePassword/ChangePassword";
import { Link } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

export default function AccountSettings() {
  return (
    <div className={styles.loffaAccountSettings}>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              Account Settings
              <div>
                <Link to="/dashboard/accountsettings/changepassword">
                  Change Password
                </Link>
              </div>
            </div>
          }
        />
        <Route
          path="*"
          element={ <ChangePassword/> }
        />
      </Routes>
    </div>
  );
}
