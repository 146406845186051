import React, {useState, useContext} from "react";
import { LButton, LFormInput, LDialog } from "@loffa/gpp-modules";
import { AuthContext } from "../../../../../context/AuthContext";
import NotificationContext from "../../../../../context/NotificationContext";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const USER_ROLES = [
  { value: 2, text: "User" },
  { value: 1, text: "Super Admin" },
];

function UserForm(props) { 
  const authContext = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);
  const [state, setState] = useState({
    user: props.selectedUser ?? {
      userGuid: null,
      firstName: "",
      lastName: "",
      email: "",
      roleTypeId: 2, // User
      isActive: true,
    },
    saveSuccessful: false,
    isAdmin: props.isAdmin,
  });

  let handleStateChange = (e) => {
    setState({
      ...state,
      user: {
        ...state.user,
        [e.target.name]: e.target.value,
      },
    });
  };

  let setDialogTitle = () => {
    return props.selectedUser ? "Edit User" : "Add New User";
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.checkValidity();
    let method = state.user.userGuid ? "PATCH" : "POST";
    let url = GATEWAY_BASE + "/multitenancy/api/users";
    let userState = state.user;

    if (typeof(userState.isActive) === 'undefined'){
      userState.isActive = true;
    }

    userState.lastUpdatedBy = authContext.userInfo().loffaUserGuid;

    // NOTE: If we get a 400 error on this fetch, regarding an invalid Token, it means the Okta generated token is expired,
    // because it hasn't been used in 30 days. We need to regenerate and update the token in app.settings in the MT API Project 

    fetch(url, {
      method: method,
      mode: "cors",
      headers: {
        Authorization: `Bearer ${authContext.authState().accessToken.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userState),
    }).then(
      (result) => {
        if (result.ok){
          props.saveForm(result.userGuid);
          notificationContext.addSuccess(state.user.firstName + " " + state.user.lastName + " - User Saved.");
        }
        else{
          return result.json();
        }
      }
    ).then((result) => {
      if (typeof(result) !== 'undefined') {        
        props.failedForm();
        notificationContext.addError(result.errorMessage?result.errorMessage:result.message?result.message:"An Unknown Error has occured");
      }
    });
  };

  let selectRole = (e) => {
    setState({
      ...state,
      user: {
        ...state.user,
        roleTypeId: e.value.value,
      },
    });
  };

  let toggleActivate = () => {
    setState({
      ...state,
      user: {
        ...state.user,
        isActive: !state.user.isActive,
      },
    });
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <LDialog
          width="500"
          title={setDialogTitle()}
          body={
            <div>
              {state.user.userGuid &&
                state.user.userGuid !==
                authContext.userInfo().loffaUserGuid &&
                (
                  <LButton onClick={toggleActivate} type="submit">
                    {state.user.isActive ? "Deactivate" : "Activate"}
                  </LButton>
                )}
              <div className="required-field">* required</div>
              <LFormInput
                labelName="First Name *"
                type="input"
                onChange={(event) => handleStateChange(event)}
                input={{
                  value: state.user.firstName,
                  onChange: (event) => handleStateChange(event),
                  name: "firstName",
                  required: true,
                }}
              />
              <LFormInput
                labelName="Last Name"
                type="input"
                onChange={(event) => handleStateChange(event)}
                input={{
                  value: state.user.lastName,
                  onChange: (event) => handleStateChange(event),
                  name: "lastName",
                  required: true,
                }}
              />
              <LFormInput
                labelName="Email *"
                type="input"
                onChange={(event) => handleStateChange(event)}
                input={{
                  value: state.user.email,
                  onChange: (event) => handleStateChange(event),
                  name: "email",
                  required: true,
                }}
              />
              {state.isAdmin && (
                <LFormInput
                  labelName="Role *"
                  type="combobox"
                  comboBox={{
                    data: USER_ROLES,
                    textField: "text",
                    dataItemKey: "value",
                    required: true,
                    defaultValue: USER_ROLES.find((role) => {
                      return role.value === state.user.roleTypeId;
                    }),
                    onChange: (event) => selectRole(event),
                  }}
                />
              )}
            </div>
          }
          actionLayout="end"
          action={
            <React.Fragment>
              <LButton onClick={props.closeForm}>Cancel</LButton>
              <LButton themeColor="primary" className="green" type="submit">
                Save
              </LButton>
            </React.Fragment>
          }
          actionCloseButton={props.closeForm}
          actionOverlay={props.closeForm}
        />
      </form>
    </div>
  );
}

export { UserForm };