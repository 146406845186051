import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LCard, LButton } from "@loffa/gpp-modules";
import { ProductManagement } from "./ProductManagement/ProductManagement";
import { ClientManagement } from "./ClientManagement/ClientManagement";
import { UserManagement } from "./UserManagement/UserManagement";
import styles from "./ManagementDashboard.module.css";

import {ServiceManagement } from "./ServiceManagement/ServiceManagement";

function ManagementDashboard(props) {

  const [state, setState] = useState({
    view: 0,
    sectionName: "",
  });

  const viewStateChange = (viewNumber) => {
    let newState = { view: viewNumber };
    switch (viewNumber) {
      case 1:
        setState({ ...newState, sectionName: " | Client Management" });
        break;
      case 2:
        setState({ ...newState, sectionName: " | Product Management" });
        break;
      case 3:
        setState({ ...newState, sectionName: " | User Management" });
        break;
        case 4: 
        setState({ ...newState, sectionName: " | Service Management" });
        break;
      default:
        setState({ ...newState, sectionName: "" });
    }
  };

  return (
    <div className={styles.managementDashboard}>
      <div className="sectionTitle">
        <FontAwesomeIcon icon="cog" /> Administration
        {state.view !== 0 && (
          <span className={styles.managementSectionTitle}>
            {state.sectionName}
          </span>
        )}
        {state.view !== 0 && (
          <div className={styles.managementShortcut}>
            <LButton
              look="flat"
              onClick={() => viewStateChange(1)}
              disabled={state.view === 1}
            ><FontAwesomeIcon icon="fa-sharp fa-solid fa-building" /> Clients</LButton>{" "}
            <LButton
              look="flat"
              onClick={() => viewStateChange(2)}
              disabled={state.view === 2}
            ><FontAwesomeIcon icon="fa-solid fa-grid-2-plus"  /> Products</LButton>{" "}
            <LButton
              look="flat"
              onClick={() => viewStateChange(3)}
              disabled={state.view === 3}
                      ><FontAwesomeIcon icon="fa-solid fa-users-gear" /> Users</LButton>
                      <LButton
                          look="flat"
                          onClick={() => viewStateChange(4)}
                          tooltip="Service Management"
                          tooltipPosition="bottomRight"
                          disabled={state.view === 4}
                      ><FontAwesomeIcon icon="fa-screwdriver-wrench" /> Services</LButton>
          </div>
        )}
      </div>
      {state.view === 0 && (
        <div className={styles.managementCards}>
          <LCard
            className={styles.managementCard}
            style={{ width: "300px" }}
            title={
              <div>
                <FontAwesomeIcon
                  className={styles.managementIcon}
                  icon="fa-sharp fa-solid fa-building"
                />
                <br />
                Client Management
              </div>
            }
            subtitle="Create and manage new clients and configure their products and users"
            clickAction={() => viewStateChange(1)}
          ></LCard>
          <LCard
            className={styles.managementCard}
            style={{ width: "300px" }}
            title={
              <div>
                <FontAwesomeIcon
                  className={styles.managementIcon}
                  icon="fa-solid fa-grid-2-plus" 
                />
                <br />
                Product Management
              </div>
            }
            subtitle="Create and manage products and configure their global settings"
            clickAction={() => viewStateChange(2)}
          ></LCard>
          <LCard
            className={styles.managementCard}
            style={{ width: "300px" }}
            title={
              <div>
                <FontAwesomeIcon
                  className={styles.managementIcon}
                  icon="fa-solid fa-users-gear"
                />
                <br />
                User Management
              </div>
            }
            subtitle="Create and manage users including administrators"
            clickAction={() => viewStateChange(3)}
          ></LCard>

          <LCard
            className={styles.managementCard}
            style={{ width: "300px" }}
            title={
              <div>
                <FontAwesomeIcon
                  className={styles.managementIcon}
                  icon="fa-screwdriver-wrench"
                />
                <br />
                Service Management
              </div>
            }
            subtitle="Manage services and their configurations"
            clickAction={() => viewStateChange(4)}
          ></LCard>


        </div>
      )}
      {state.view !== 0 && (
        <div className={styles.managementViewContainer}>
          {state.view === 1 && <ClientManagement />}
          {state.view === 2 && <ProductManagement />}
          {state.view === 3 && <UserManagement />}
          {state.view === 4 && <ServiceManagement />}
        </div>
      )}
    </div>
  );
}

export { ManagementDashboard };
