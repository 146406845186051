import React, { useContext, useState, useEffect, useCallback } from "react";
import { LTabStrip, LButton, LGrid } from "@loffa/gpp-modules";
import { AuthContext } from "../../../../context/AuthContext";

function ServiceManagement() {
  const context = useContext(AuthContext);
  const [dtcData, setDTCData] = useState(null);

  const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

  const SeedDTCDatabase = async () => {
    let header = {
      Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
      "Content-Type": "application/json",
    };

    let init = { method: 'GET', accept: 'application/json', headers: header, timeout: 10000 };
    let url = GATEWAY_BASE + "/multitenancy/api/Integration/UpdateAllDTCs";

    fetch(url, init)
      .then(response => response.json())
      .then(json => {
        console.log(json);
        setDTCData({ json });
      });
  };

  const GetDTCData = useCallback(async () => {
    let header = {
      Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
      "Content-Type": "application/json",
    };

    let init = { method: 'GET', accept: 'application/json', headers: header, timeout: 10000 };
    let url = GATEWAY_BASE + "/multitenancy/api/Integration/GetAllDTCs";

    fetch(url, init)
      .then(response => response.json())
      .then(json => {
        console.log(json);
        setDTCData(json);
      });
  }, [GATEWAY_BASE, context]);

  useEffect(() => {
    if (dtcData == null) {
      GetDTCData();
    }
  }, [GetDTCData, dtcData]);

  return (
    <div>
      <LTabStrip
        tabs={[
          {
            title: "FVD Integration",
            body: (
              <div>
       

                <LGrid
                  sortable
                  filterable
                  pageable
                  data={dtcData}
                  style={{ height: "400px" }}
                  columns={[
                    {
                      field: "DTC",
                      title: "DTC",
                    },
                    {
                      field: "ClientProductGuid",
                      title: "ClientProductGuid",
                    },
                    {
                      field: "ClientName",
                      title: "Client Name"
                    },
                    {
                      field: "ProductName",
                      title: "Product Name"
                    },
                    {
                      field: "ProductType",
                      title: "Product Type"
                    }
                  ]}
                ></LGrid>

              </div>
            ),
          },
          {
            title: "Other Tab",
            disabled: true,
            body: (
              <div>
                <h4>Other</h4>
                <p>Coming soon...</p>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export { ServiceManagement };
