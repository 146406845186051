import React, { useEffect, useCallback } from "react";
import { withOktaAuth } from "@okta/okta-react";

const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const REACT_APP_OKTA_AUTHZ_PATH = process.env.REACT_APP_OKTA_AUTHZ_PATH;

export default withOktaAuth(
  function Logout(props) {
    let homeURL = window.location.origin;
    const logout = useCallback(async () => {

      if (!props.authState.isPending) {
        const token = props.authState.idToken.value;

        // Clear local session
        await props.oktaAuth.signOut("/");

        //Clear remote session
        window.location.href = `https://${OKTA_DOMAIN + REACT_APP_OKTA_AUTHZ_PATH
          }/v1/logout?id_token_hint=${token}&post_logout_redirect_uri=${homeURL
          }`;
      }
    }, [homeURL, props]);

    useEffect(() => {
      logout();
    }, [logout]);

    if (props.authState.isPending) {
      return <div>Loading...</div>;
    }

    return <div>Logging out...</div>;
  }
);
