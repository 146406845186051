import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "../Routing/Routing";
import { AuthProvider } from "../../context/AuthContext";
import { NotificationProvider } from "../../context/NotificationContext";
import styles from "./App.module.css";
import '../../styles/Shared.css';
import '@loffa/gpp-modules/dist/main.css';
import '../../icon-library';

function App() {
  return (
    <div className={styles.app}>
      <AuthProvider>
        <NotificationProvider>
          <Router>
            <Routing />
          </Router>
        </NotificationProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
