import React, { useState, useContext, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LTabStrip, LButton } from "@loffa/gpp-modules";
import { CPUserManagement } from "./CPUserManagement/CPUserManagement";
import { CPConfigurationManagement } from "./CPConfigManagement/CPConfigManagement";
import { AuthContext } from "../../../../../../context/AuthContext";
import styles from "./CProductManagement.module.css";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

function ClientProductManagement(props) {
  const context = useContext(AuthContext);
  const [state, setState] = useState({
    selectedClient: props.selectedClient,
    selectedProduct: props.selectedProduct,
    clientProductUsers: null,
    isLoading: false
  });
  const [productURL, setProductURL] = useState(null);
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const bindData = useCallback(() => {
    setState(s => ({ ...s, isLoading: true }));

    let url =
      GATEWAY_BASE +
      "/multitenancy/api/Clients/" +
      state.selectedClient.clientGuid +
      "/Products/" +
      state.selectedProduct.productGuid +
      "/Users?isAssigned=true";

    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
      },
    })
      .then((res) => {
        if (res.status === 204) {
          return [];
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setState(s => ({ ...s, clientProductUsers: response }));
      })
      .then(() => {
        // Get product URL for User configuration
        let productUrl =
          GATEWAY_BASE +
          "/multitenancy/api/products/" +
          state.selectedProduct.productGuid;

        return fetch(productUrl, {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${context.authState().accessToken.accessToken}`,
            "Content-Type": "application/json",
          },
        });
      })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        setProductURL(response.url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [context, state.selectedClient.clientGuid, state.selectedProduct.productGuid]);

  useEffect(() => {
    if (state.clientProductUsers === null){
      bindData();
    }
  }, [bindData, state.clientProductUsers]);

  useEffect(() => {
    if (productURL !== null && state.isLoading === true){
      setState(s => ({ ...s, isLoading: false }));
    }
  }, [productURL, state.isLoading]);


  return (
    <div className={styles.clientProductManagementGrid}>
                <LButton
            onClick={() => props.onBackClick()}
            fillMode="flat"
            tooltipPosition="bottomLeft"
            tooltip="Back to Client Page"
          ><FontAwesomeIcon icon="fa-backward-step"/> Back</LButton> 
      { state.isLoading && loadingPanel }
      <div className={styles.clientNameHeader}>
        <div className={styles.clientName}>
          {state.selectedClient.name}
        </div>
        <div className={styles.clientProduct}>
          {state.selectedProduct.name}
        </div>
      </div>
      <LTabStrip
        tabs={[
          {
            title: "Users",
            body: (
              <CPUserManagement
                productUrl={productURL}
                selectedClient={state.selectedClient}
                selectedProduct={state.selectedProduct}
                data={state.clientProductUsers}
                rebind={bindData}
              />
            ),
          },
          {
            title: "Configuration",
            body: (
              <CPConfigurationManagement
                clientGuid={state.selectedClient.clientGuid}
                productGuid={state.selectedProduct.productGuid}                
              />
            ),
          },
        ]}
      />
    </div>
  );
}

export { ClientProductManagement };
