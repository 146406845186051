import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';


export default function JITUserCreationView() {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
    var auth = oktaAuth;

    auth.session.exists().then(function (sessionExists) {
      if (sessionExists) {
        auth.token.getWithoutPrompt().then(function (response) {

          //This sets the token from SAML to local storage
          auth.tokenManager.setTokens(response.tokens);

          // We check our DB for the user, and create them if they don't exist
          //var storageValueToken = auth.storageManager.getTokenStorage().storageProvider["okta-token-storage"]
          var token = response.tokens; //auth.tokenManager.getTokensFromStorageValue(storageValueToken);

          fetch(
            GATEWAY_BASE +
            "/multitenancy/api/users/CreateUserFromToken",
            {
              method: "POST",
              mode: "cors",
              headers: {
                Authorization: `Bearer ${token.accessToken.accessToken}`,
              },
            }
          )
            .then((res) => {
              if (!res.ok) {
                throw new Error("Server error"); // Throw a generic error for non-OK responses
              }
              return res.json();
            })
            .then(
              (result) => {
                auth.tokenManager.setTokens(token);
                navigate("/");
              },
              (error) => {
                console.error("Custom error:", error.message);
              }
            );
        })
      }
    });
  }, [navigate, oktaAuth]);
  
  return (
    <div>
      Signing user in...
    </div>
  );
};
