import React from "react";
import { ProductTable } from "./ProductTable/ProductTable";

function ProductManagement(props) {
  return (
    <div>
      <ProductTable></ProductTable>
    </div>
  );
}

export { ProductManagement };
